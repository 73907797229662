import React from 'react'
import {withTranslation} from 'react-i18next'
import axios from 'axios'
import {FormRow, FormInput, FormButtonBar}  from './../common/Forms.jsx'
import CenteredModal from './../modal/CenteredModal.jsx'

class MonitoringPasswordChange extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            data: {
                firstPassword: '',
                secondPassword: '',
            },
            error: {},
            modal: {
                displaySuccess: false,
                displayInternalError: false,
            },
            loading: false,
            apiSubmitUrl: this.props.passwordChangeSubmitUrl,
        }
    }

    handlePasswordInputChange = (event) => {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name

        let pw1
        let pw2

        if (name === 'firstPassword') {
            pw1 = value
            pw2 = this.state.data.secondPassword

        } else if (name === 'secondPassword') {
            pw1 = this.state.data.firstPassword
            pw2 = value
        }

        let matchingError = null

        if (pw1 && pw2) {
            if (pw1 !== pw2) {
                matchingError = this.props.t('password.change.notMatching')
            }
        }
        this.setState({
            data: {
                firstPassword: pw1,
                secondPassword: pw2,
            },
            error: {
                notMatching: matchingError,
            }
        })

    }

    handleSubmit = (event) => {

        if (this.state.data.firstPassword !== this.state.data.secondPassword) {
            return
        }

        this.setState({
            loading: true
        })

        let passwordObject = {}
        passwordObject.newPassword = this.state.data.firstPassword

        axios.post(this.state.apiSubmitUrl, JSON.stringify(passwordObject), {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
        }).then(success => this.handleSubmitSuccess(success.data)
        ).catch(error => this.handleFormError(error.response))
    }

    handleSubmitSuccess = (data) => {
        let cb = () => {}

        if(data.state === 'OK' && data.redirectUrl) {
            cb = () => window.location.href = data.redirectUrl
        }
        this.setState({
            successCallback: cb,
            loading: false,
            modal: {
                displaySuccess: true
            },
            data: {
                firstPassword: "",
                secondPassword: ""
            }
        });
    }

    handleFormError = (response) => {
        this.setState({
            loading: false,
            modal: {
                displayInternalError: true
            },
            data: {
                firstPassword: '',
                secondPassword: ''
            }
        })
    }

    closeModal = (target) => {
        if(target === 'displaySuccess'){
            this.state.successCallback();
        }
        this.setState({
            modal: {
                ...this.state.modal, [target]: false
            },
        })
    }

    render() {
        const t = this.props.t

        const isButtonDisabled = this.state.loading || !this.state.data.firstPassword || !this.state.data.firstPassword
            || (this.state.data.firstPassword !== this.state.data.secondPassword)

        const firstPassword = (
            <FormInput
                name='firstPassword'
                type='password'
                autoComplete='new-password'
                placeholder={t('password.change.firstLabel')}
                value={this.state.data.firstPassword}
                error={this.state.error.notMatching}
                handleInputChange={(event) => this.handlePasswordInputChange(event)}/>
        )

        const secondPassword = (
            <FormInput
                name='secondPassword'
                type='password'
                autoComplete='new-password'
                placeholder={t('password.change.secondLabel')}
                value={this.state.data.secondPassword}
                error={this.state.error.notMatching}
                handleInputChange={(event) => this.handlePasswordInputChange(event)}
            />
        )

        const submitButton = (
            <div className='control is-expanded has-text-centered'>
                <button className={'button is-primary ' + (this.state.loading ? 'is-loading' : '')}
                        onClick={this.handleSubmit} disabled={isButtonDisabled}>
                    <span>
                        {t('password.change.buttonText')}
                    </span>
                </button>
            </div>
        )

        const modalHolder = (
            <React.Fragment>
                {this.state.modal.displaySuccess &&
                <CenteredModal
                    title={t('password.modal.success.title')}
                    body={t('password.modal.success.body')}
                    handleClose={(() => this.closeModal('displaySuccess'))}/>
                }

                {this.state.modal.displayInternalError &&
                <CenteredModal
                    title={t('password.modal.error.title')}
                    body={t('password.modal.error.body')}
                    handleClose={(() => this.closeModal('displayInternalError'))}/>
                }
            </React.Fragment>
        )
        return (
            <div>
                {modalHolder}
                <div className='field is-horizontal'>
                    <div className='field-body limit-field-dimension'>
                        <div className='field is-grouped is-grouped-multiline'>
                            {firstPassword}
                            {secondPassword}
                            {submitButton}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default withTranslation('translations')(MonitoringPasswordChange)
