import React from 'react'
import MonitoringTable from './monitoring/MonitoringTable.jsx'
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker from 'react-datepicker'
import moment from 'moment/moment'
import {withTranslation} from 'react-i18next'
import axios from 'axios/index'
import FileDownload from 'js-file-download'
import {FormInput} from './common/Forms.jsx'

const ALL = 'ALL'

const ID_SUCCESS = 'ID_SUCCESS'
const ID_FAILURE = 'ID_FAILURE'
const ID_TIMEOUT = 'ID_TIMEOUT'
const ID_STATUS_LIST = [ALL, ID_SUCCESS, ID_TIMEOUT, ID_FAILURE]

const ID_PROV_WEBID = 'WEBID'
const ID_PROV_AUTHDATA = 'AUTHDATA'
const ID_PROV_NECT = 'NECT'
const ID_PROV_LIST = [ALL, ID_PROV_WEBID, ID_PROV_AUTHDATA, ID_PROV_NECT]

// Use 'WAITING_FOR_ID' to query all processes without issued certificate in accordance with the StatisticService
const CERT_ISSUED = 'CERT_ISSUED'
const CERT_WAITING_FOR_ID = 'CERT_WAITING_FOR_ID'
const CERT_ACTIVATION = 'CERT_ACTIVATION' // Do not add to Status list, is shown as 'inactive' just as CERT_WAITING_FOR_ID
const CERT_STATUS_LIST = [ALL, CERT_ISSUED, CERT_WAITING_FOR_ID]

const statusTranslationMap = new Map()
statusTranslationMap.set(ALL, 'monitoring.all')
statusTranslationMap.set(ID_SUCCESS, 'monitoring.identificationState.success')
statusTranslationMap.set(ID_FAILURE, 'monitoring.identificationState.failure')
statusTranslationMap.set(ID_TIMEOUT, 'monitoring.identificationState.timeout')
statusTranslationMap.set(ID_PROV_WEBID, 'monitoring.idProvider.webId')
statusTranslationMap.set(ID_PROV_AUTHDATA, 'monitoring.idProvider.authData')
statusTranslationMap.set(ID_PROV_NECT, 'monitoring.idProvider.nect')
statusTranslationMap.set(CERT_ISSUED, 'monitoring.certificateState.certificateIssued')
statusTranslationMap.set(CERT_ACTIVATION, 'monitoring.certificateState.noCertificate')
statusTranslationMap.set(CERT_WAITING_FOR_ID, 'monitoring.certificateState.noCertificate')

class MonitoringForm extends React.Component {
    constructor(props) {
        super(props)

        if (Array.isArray(this.props.customerList) && this.props.customerList.length) {
            this.props.customerList.unshift(ALL)
        }

        this.state = {
            filterStartDate: moment().subtract(7, 'd').startOf('day').toDate(),
            filterEndDate: moment().endOf('day').toDate(),
            filterStartDateError: false,
            filterEndDateError: false,
            filterIdentificationStatus: ALL,
            filterCertificateStatus: ALL,
            filterCustomerName: ALL,
            filterIdProvider: ALL,
            filterSecondaryId: "",
            filterResult: [],
            filterByTimeType: 'SESSION_START',
            displayIdentificationStatusDropdown: false,
            displayCertificateStatusDropdown: false,
            displayCustomerStatusDropdown: false,
            displayIdProviderDropdown: false,
            loadingFilter: false,
            loadingCsvDownload: false,
            isAdmin: false,
            customerList: this.props.customerList,
            apiFilterUrl: this.props.apiFilterUrl,
            apiDownloadCsvUrl: this.props.apiDownloadCsvUrl,
        }
    }

    toggleDropdownState = (targetState) => {
        let newState = !this.state[targetState]
        this.setState({
            [targetState]: newState,
        })
    }

    resetFilterStartDate = () => {
        this.setState({
            filterStartDate: moment([2017]).toDate(),
        })
    }

    resetFilterEndDate = () => {
        this.setState({
            filterEndDate: moment().endOf('day').toDate(),
        })
    }

    handleFilterTextInputChange = (event) => {
        this.setState({
            filterSecondaryId: event.target.value
        })
    }

    handleFilterDateChange = (targetState, targetStateError, date) => {
        this.setState({
            [targetState]: date,
            [targetStateError]: false,
        })
    }

    handleFilterDateChangeRaw = (targetState, targetStateError, rawDate) => {
        let validatedDate = moment(rawDate, 'DD.MM.YYYY', true)
        if (validatedDate.isValid()) {
            this.handleFilterDateChange(targetState, targetStateError, validatedDate)
        } else {
            this.setState({
                [targetStateError]: true,
            })
        }
    }

    handleFilterIdentificationStatusChange = (event, state) => {
        event.preventDefault()
        this.setState({
            filterIdentificationStatus: state,
            displayIdentificationStatusDropdown: false,
        })
    }

    handleFilterCertificateStatusChange = (event, state) => {
        event.preventDefault()
        this.setState({
            filterCertificateStatus: state,
            displayCertificateStatusDropdown: false,
        })
    }

    handleFilterCustomerStatusChange = (event, state) => {
        event.preventDefault()
        this.setState({
            filterCustomerName: state,
            displayCustomerStatusDropdown: false,
        })
    }

    handleFilterIdProviderChange = (event, state) => {
        event.preventDefault()
        this.setState({
            filterIdProvider: state,
            displayIdProviderDropdown: false,
        })
    }


    handleApplyFilter = () => {
        this.setState({
            loadingFilter: true,
        })

        axios.post(this.state.apiFilterUrl, JSON.stringify(this.prepareFilterData()), {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
        }).then(success => this.handleApplyFilterSuccess(success.data)
        ).catch(error => this.handleApplyFilterError(error.response.data))
    }

    handleDownloadCsv = () => {
        this.setState({
            loadingCsvDownload: true,
        })

        axios.post(this.state.apiDownloadCsvUrl, JSON.stringify(this.prepareFilterData()), {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
        }).then((response) => {
            FileDownload(response.data, 'report_' + moment().format('DD_MM_YY') + '.csv')
            this.setState({
                loadingCsvDownload: false,
            })
        })
    }

    handleApplyFilterSuccess = (data) => {
        this.prepareResponseData(data)
        this.setState({
            loadingFilter: false,
            filterByTimeType: data.filterByTimeType,
            filterResult: data.entries,
        })
        console.log('success: ' + data)

    }

    handleApplyFilterError = (data) => {
        this.setState({
            loadingFilter: false,
        })
        if (data.status === 403) {
            window.location = 'auth/error'
        }
        console.log('error: ' + data)
    }

    prepareFilterData = () => {
        return {
            startDate: this.state.filterStartDate.toISOString(),
            endDate: this.state.filterEndDate.toISOString(),
            identificationState: this.state.filterIdentificationStatus === ALL ? null : this.state.filterIdentificationStatus,
            certificateState: this.state.filterCertificateStatus === ALL ? null : this.state.filterCertificateStatus,
            customerName: this.state.filterCustomerName === ALL ? null : this.state.filterCustomerName,
            idProvider: this.state.filterIdProvider === ALL ? null : this.state.filterIdProvider,
            secondaryId: this.state.filterSecondaryId === "" ? null : this.state.filterSecondaryId,
        }
    }

    prepareResponseData = (monitoringResult) => {

        monitoringResult.entries.forEach(info => {
            info.identificationState = this.props.t(statusTranslationMap.get(info.identificationState))
            info.certificateState = this.props.t(statusTranslationMap.get(info.certificateState))
            switch (monitoringResult.filterByTimeType) {
                case 'SESSION_START':
                    info.dateHistory = ''.concat('<',
                        (info.sessionStartTime === '-') ? '-' : moment(info.sessionStartTime).format('DD.MM.YY, HH:mm'), '> / ',
                        (info.identificationTime === '-') ? '-' : moment(info.identificationTime).format('DD.MM.YY, HH:mm'), ' / ',
                        (info.certificateActivationTime === '-') ? '-' : moment(info.certificateActivationTime).format('DD.MM.YY, HH:mm')
                    )
                    info.date = info.sessionStartTime === '-' ? '-' : moment(info.sessionStartTime)
                    break
                case 'IDENTIFICATION':
                    info.dateHistory = ''.concat(
                        (info.sessionStartTime === '-') ? '-' : moment(info.sessionStartTime).format('DD.MM.YY, HH:mm'), ' / <',
                        (info.identificationTime === '-') ? '-' : moment(info.identificationTime).format('DD.MM.YY, HH:mm'), '> / ',
                        (info.certificateActivationTime === '-') ? '-' : moment(info.certificateActivationTime).format('DD.MM.YY, HH:mm')
                    )
                    info.date = info.identificationTime === '-' ? '-' : moment(info.identificationTime)
                    break
                case 'CERTIFICATE_ACTIVATION':
                    info.dateHistory = ''.concat(
                        (info.sessionStartTime === '-') ? '-' : moment(info.sessionStartTime).format('DD.MM.YY, HH:mm'), ' / ',
                        (info.identificationTime === '-') ? '-' : moment(info.identificationTime).format('DD.MM.YY, HH:mm'), ' / <',
                        (info.certificateActivationTime === '-') ? '-' : moment(info.certificateActivationTime).format('DD.MM.YY, HH:mm'), '>'
                    )
                    info.date = info.certificateActivationTime === '-' ? '-' : moment(info.certificateActivationTime)
                    break
            }


        })
    }

    render() {
        const {t, i18n} = this.props

        const startFilter = (
            <DatePicker
                name='startDate'
                className={'input datepicker-min-width ' + (this.state.filterStartDateError ? 'is-danger' : '')}
                selected={this.state.filterStartDate}
                onChange={(date) => this.handleFilterDateChange('filterStartDate', 'filterStartDateError', date)}
                onChangeRaw={(event) => this.handleFilterDateChangeRaw(
                    'filterStartDate', 'filterStartDateError', event.target.value)}
                minDate={moment([2017]).toDate()}
                maxDate={this.state.filterEndDate}
                dateFormat='dd.MM.yyyy'
                locale={i18n.language}
                fixedHeight
                disabledKeyboardNavigation={true}
                shouldCloseOnSelect={true}/>
        )

        const endFilter = (
            <DatePicker
                name='endDate'
                className={'input datepicker-min-width ' + (this.state.filterEndDateError ? 'is-danger' : '')}
                selected={this.state.filterEndDate}
                onChange={(date) => this.handleFilterDateChange('filterEndDate', 'filterEndDateError', date)}
                onChangeRaw={(event) => this.handleFilterDateChangeRaw(
                    'filterEndDate', 'filterEndDateError', event.target.value)}
                minDate={this.state.filterStartDate}
                maxDate={moment().toDate()}
                dateFormat='dd.MM.yyyy'
                locale={i18n.language}
                fixedHeight
                disabledKeyboardNavigation={true}
                shouldCloseOnSelect={true}/>
        )

        const identificationFilter = (
            <StatusDropdownComponent
                state={this.state.filterIdentificationStatus}
                toggleState={this.state.displayIdentificationStatusDropdown}
                stateList={ID_STATUS_LIST}
                handleOpen={() => this.toggleDropdownState('displayIdentificationStatusDropdown')}
                handleChange={this.handleFilterIdentificationStatusChange}
                t={t}/>
        )

        const certificateFilter = (
            <StatusDropdownComponent
                state={this.state.filterCertificateStatus}
                toggleState={this.state.displayCertificateStatusDropdown}
                stateList={CERT_STATUS_LIST}
                handleOpen={() => this.toggleDropdownState('displayCertificateStatusDropdown')}
                handleChange={this.handleFilterCertificateStatusChange}
                t={t}/>
        )

        const customerFilter = (
            <CustomerDropdownComponent
                state={this.state.filterCustomerName}
                toggleState={this.state.displayCustomerStatusDropdown}
                stateList={this.state.customerList}
                handleOpen={() => this.toggleDropdownState('displayCustomerStatusDropdown')}
                handleChange={this.handleFilterCustomerStatusChange}
                t={t}/>
        )

        const idProviderFilter = (
            <StatusDropdownComponent
                state={this.state.filterIdProvider}
                toggleState={this.state.displayIdProviderDropdown}
                disabled={true}
                stateList={ID_PROV_LIST}
                handleOpen={() => this.toggleDropdownState('displayIdProviderDropdown')}
                handleChange={this.handleFilterIdProviderChange}
                t={t}/>
        )

        const secondaryIdFilter = (
            <FormInput
                name='secondaryId'
                autoComplete='off'
                value={this.state.secondaryId}
                handleInputChange={(event) => this.handleFilterTextInputChange(event)}
            />

        )

        const filterButton = (
            <StatusButtonWithLabel

                labelBundle='monitoring.label.action'
                icon='fa-search'
                textBundle='monitoring.label.doFilter'
                handleClick={this.handleApplyFilter}
                loadingState={this.state.loadingFilter}
                disabled={this.state.loadingFilter}
                t={t}/>
        )

        const downloadCsvButton = (
            <StatusButtonWithLabel
                labelBundle='monitoring.label.doDownloadCsvLabel'
                icon='fa-download'
                textBundle='monitoring.label.doDownloadCsv'
                handleClick={this.handleDownloadCsv}
                loadingState={this.state.loadingCsvDownload}
                disabled={this.state.loadingCsvDownload}
                t={t}/>
        )

        const resultSubsectionLabel =
            (Array.isArray(this.state.filterResult) && this.state.filterResult.length)
                ? t('monitoring.subsectionResult') + ' (' + this.state.filterResult.length + ')'
                : t('monitoring.subsectionResult')

        return (
            <div>
                <div className='is-divider' data-content={t('monitoring.subsectionFilter')}/>
                <div className='field is-horizontal horizontal-filter-component'>
                    <div className='field-body'>
                        <div className='field'>
                            <label className='label'>{t('monitoring.label.startDate')}</label>
                            <div className='field has-addons'>
                                <div className='control is-expanded  '>
                                    {startFilter}
                                    {this.state.filterStartDateError &&
                                    <p className='help is-danger has-text-left'>
                                        {this.props.t('monitoring.filterError')}
                                    </p>
                                    }
                                </div>
                                <div className='control'>
                                    <button className={'button ' + (this.state.filterStartDateError ? 'is-danger' : '')}
                                            onClick={this.resetFilterStartDate}>
                                        <span className='icon is-small'>
                                          <i className='fa fa-undo'/>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='field'>
                            <label className='label'>{t('monitoring.label.endDate')}</label>
                            <div className='field has-addons'>
                                <div className='control is-expanded  '>
                                    {endFilter}
                                    {this.state.filterEndDateError &&
                                    <p className='help is-danger has-text-left'>
                                        {this.props.t('monitoring.filterError')}
                                    </p>
                                    }
                                </div>

                                <div className='control'>
                                    <button className={'button ' + (this.state.filterEndDateError ? 'is-danger' : '')}
                                            onClick={this.resetFilterEndDate}>
                                        <span className='icon is-small'>
                                          <i className='fa fa-undo'/>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className='field'>
                            <label className='label'>{t('monitoring.label.identification')}</label>
                            <div className='control is-expanded  '>
                                {identificationFilter}
                            </div>
                        </div>

                        <div className='field'>
                            <label className='label'>{t('monitoring.label.certificate')}</label>
                            <div className='control is-expanded  '>
                                {certificateFilter}
                            </div>
                        </div>

                        <div className='field'>
                            <label className='label'>{t('monitoring.label.provider.short')}</label>
                            <div className='control is-expanded  '>
                                {idProviderFilter}
                            </div>
                        </div>



                    </div>
                </div>
                <div className='field is-horizontal horizontal-filter-component has-text-centered'>
                    <div className='field-body'>

                        {this.props.customerList.length > 0 && (
                            <div className='field'>
                                <label className='label'>{t('monitoring.label.customer')}</label>
                                <div className='control is-expanded  '>
                                    {customerFilter}
                                </div>
                            </div>
                        )}

                        <div className='field'>
                            <label className='label'>{t('monitoring.label.secondaryId.short')}</label>
                            <div className='control is-expanded  '>
                                {secondaryIdFilter}
                            </div>
                        </div>

                        <div className='field has-text-centered'>
                            {filterButton}
                        </div>

                        <div className='field has-text-centered'>
                            {downloadCsvButton}
                        </div>
                    </div>
                </div>
                <div className='is-divider' data-content={resultSubsectionLabel}/>

                <MonitoringTable
                    displayData={this.state.filterResult}
                    customerList={this.state.customerList}
                    filterByTimeType={this.state.filterByTimeType}
                    t={t}
                />
            </div>
        )
    }
}

class StatusDropdownComponent extends React.Component {

    render() {
        let {state, stateList, handleChange, t, disabled, ...otherProps} = this.props
        const dropdownItems = stateList.map((listState) =>
            <DropdownItem
                key={listState}
                typeElement={listState}
                typeDisplayName={t(statusTranslationMap.get(listState))}
                disabled={disabled}
                state={state}
                handleClick={handleChange}
                t={t}/>
        )

        return (
            <DropdownComponent
                dropdownItems={dropdownItems}
                t={t}
                disabled={disabled}
                state={state}
                stateDisplayName={t(statusTranslationMap.get(state))}
                {...otherProps}/>
        )
    }
}

class CustomerDropdownComponent extends React.Component {

    render() {
        let {state, stateList, handleChange, t, ...otherProps} = this.props
        const dropdownItems = stateList.map((customerName) =>
            <DropdownItem
                key={customerName}
                typeElement={customerName}
                typeDisplayName={customerName === ALL ? t(statusTranslationMap.get(ALL)) : customerName}
                state={state}
                handleClick={handleChange}
                t={t}/>
        )

        return (
            <DropdownComponent
                dropdownItems={dropdownItems}
                t={t}
                state={state}
                stateDisplayName={state === ALL ? t(statusTranslationMap.get(ALL)) : state}
                {...otherProps}/>
        )
    }
}

class DropdownComponent extends React.Component {
    render() {
        return (
            <div className={'dropdown  ' + (this.props.toggleState ? 'is-active' : '')}>
                <div className='dropdown-trigger'>
                    <button onClick={this.props.handleOpen}
                            disabled={this.props.disabled}
                            className='button'
                            aria-haspopup='true'
                            aria-controls='dropdown-menu'>
                        <span>{this.props.stateDisplayName}</span>
                        <span className='icon is-small'>
                            <i className='fa fa-angle-down' aria-hidden='true'/>
                        </span>
                    </button>
                </div>
                <div className='dropdown-menu' id='dropdown-menu' role='menu'>
                    <div className='dropdown-content'>
                        {this.props.dropdownItems}
                    </div>
                </div>
            </div>
        )
    }
}

class DropdownItem extends React.Component {

    render() {
        return (
            <a className={'dropdown-item  ' + (this.props.typeElement === this.props.state ? 'is-active' : '')}
               onClick={(e) => this.props.handleClick(e, this.props.typeElement)}>
                {this.props.typeDisplayName}
            </a>
        )
    }
}

class StatusButtonWithLabel extends React.Component {

    render() {
        const t = this.props.t

        return (
            <React.Fragment>
                <label className='label'>{t(this.props.labelBundle)}</label>
                <div className='control is-expanded has-text-centered'>
                    <button className={'button is-primary ' + (this.props.loadingState ? 'is-loading' : '')}
                            onClick={this.props.handleClick} disabled={this.props.disabled}>
                        <span className='icon is-small'>
                            <i className={'fa ' + this.props.icon}/>
                        </span>
                        <span>
                            {t(this.props.textBundle)}
                        </span>
                    </button>
                </div>
            </React.Fragment>
        )
    }
}

export default withTranslation('translations')(MonitoringForm)
