import React from 'react'
import {withTranslation} from 'react-i18next'

import CookieConsent from "react-cookie-consent";


class CookieConfirmComponent extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const {t, i18n} = this.props

        return (
          <CookieConsent
            location="bottom"
            buttonText={t('cookiePolicy.button')}
            cookieName="user-has-accepted-cookies"
            buttonClasses="button-close"
            sameSite="none"
            style={{ background: "#034ea2" }}

          >
              {t('cookiePolicy.message') + " "}
              <a target='_blank' href={'/privacypolicy#cookies'}>{t('cookiePolicy.link')}</a>
          </CookieConsent>
        )
    }
}


// extended main view with translate hoc
export default withTranslation('translations')(CookieConfirmComponent)
