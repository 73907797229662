import React from 'react'
import ReactDOM from 'react-dom'
import BaseModal from './BaseModal.jsx'
import {withTranslation} from 'react-i18next'

const modalRoot = document.getElementById('dynamic-modal-root')

class FormFieldMatchingModal extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        const {t, i18n} = this.props

        const element = (
            <React.Fragment>
                <button className='button is-primary' onClick={this.props.handleConfirm}>
                    {t('modal.formFieldsMatching.confirm')}</button>
                <button className='button' onClick={this.props.handleClose}>
                    {t('modal.formFieldsMatching.abort')}</button>
            </React.Fragment>
        )


        let infoModal = (
            <BaseModal
                headerTitle={t('modal.formFieldsMatching.headerTitle')}
                headerSubtitle={t('modal.formFieldsMatching.headerSubtitle')}
                body={t('modal.formFieldsMatching.body')}
                footer={element}
                handleClose={this.props.handleClose}
                handleConfirm={this.props.handleConfirm}/>
        )

        return (
            ReactDOM.createPortal(
                infoModal,
                modalRoot,
            )
        )
    }
}

export default withTranslation('translations')(FormFieldMatchingModal)