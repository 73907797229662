import React from 'react'

import {withTranslation} from 'react-i18next'
import TanErrorModal from './modal/TanErrorModal.jsx'
import InternalErrorModal from './modal/InternalErrorModal.jsx'
import axios from 'axios'

class TanForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            tan: '',
            displayTanError: false,
            displaySessionTimeouted: false,
            displayInternalError: false,
            apiSubmitUrl: props.apiSubmitUrl,
        }
    }

    handleSubmit = (event) => {
        this.setState({
            loading: true
        })

        let stateData = {
            tan : this.state.tan
        }
        axios.post(this.state.apiSubmitUrl, JSON.stringify(stateData), {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true
        }).then(success => this.handleFormSubmitSuccess(success.data)
        ).catch(error => this.handleFormError(error.response.data))

        event ? event.preventDefault() : null
    }

    handleTanChange = (event) => {
        const value = event.target.value
        const cond = /^[a-zA-Z0-9]{0,6}$/

        if(value != null && cond.test(value)) {

            this.setState({
                tan: value,
            })
        }
    }

    handleFormError = (eventData) => {

        if(eventData === 'invalidTan') {
            this.setState({
                loading: false,
                tan: '',
                displayTanError: true,
                displayInternalError: false,
                displaySessionTimeouted: false,
            })
        } else {
            this.setState({
                loading: false,
                tan: '',
                displayTanError: false,
                displayInternalError: true,
                displaySessionTimeouted: false,
            })
        }
    }

    handleFormSubmitSuccess = (redirectUrl) => {
        this.setState({
            loading: false,
        })
        console.log('somwthing cool')
        window.location.replace(redirectUrl)
    }

    closeModal = (target) =>  {
        this.setState({
            [target]: false,
        })
    }

    render() {

        const {t} = this.props

        const inputField = (
            <div className='control'>
                <input className='input' placeholder={t('tan.placeholder')}
                       onChange={this.handleTanChange} value={this.state.tan}/>
            </div>
        )

        const submitButton = (
            <div className='control'>
                <button type='submit' className={'button is-primary ' + (this.state.loading ? 'is-loading' : '')}
                        onClick={this.handleSubmit} disabled={this.state.loading}>
                                        <span className='icon is-small'>
                                            <i className='fa fa-check-circle'/>
                                        </span>
                    <span>{t('tan.button')}</span>
                </button>
            </div>
        )

        return (
                <form>

                    { this.state.displayInternalError &&
                        <InternalErrorModal handleClose={(() =>this.closeModal('displayInternalError'))}/>
                    }

                    { this.state.displayTanError &&
                        <TanErrorModal handleClose={(() =>this.closeModal('displayTanError'))}/>
                    }

                    { this.state.displaySessionTimeouted &&
                        <SessionTimeoutedModal handleClose={(() => this.closeModal('displaySessionTimeouted'))}/>
                    }

                    <div className='field is-horizontal'>
                        <div className='field-body'>
                            <div className='field is-expanded is-grouped field-centered-buttons'>
                                {inputField}
                            </div>
                        </div>
                    </div>

                    <div className='field is-horizontal'>
                        <div className='field-body'>
                            <div className='field is-expanded is-grouped field-centered-buttons'>
                                {submitButton}
                            </div>
                        </div>
                    </div>
                </form>
        )
    }
}

export default withTranslation('translations')(TanForm)