import React from 'react'

import DatePicker from 'react-datepicker'
import { registerLocale } from 'react-datepicker';
import moment from 'moment'
import {withTranslation} from 'react-i18next'
import FormErrorModal from './modal/FormErrorModal.jsx'
import TOUModal from './modal/TOUModal.jsx'
import FormFieldMatchingModal from './modal/FormFieldMatchingModal.jsx'
import SessionTimeoutedModal from './modal/SessionTimeoutedModal.jsx'
import InternalErrorModal from './modal/InternalErrorModal.jsx'
import axios from 'axios'
import {FormButtonBar, FormInput}  from './common/Forms.jsx'

import { CountryDropdown } from 'react-country-region-selector'
import { de, enGB } from 'date-fns/locale'

registerLocale('en', enGB)
registerLocale('de', de)

import 'react-datepicker/dist/react-datepicker.css'

class IdentificationForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = IdentificationForm.getInitialState(props.t, props.data)
    }

    static getInitialState(t, data) {
        const apiSubmitUrl = data.apiSubmitUrl
        const pd = JSON.parse(data.prefilledUserData ? data.prefilledUserData : '{}')
        return {
            data: {
                gender: pd.gender
                    ? (pd.gender === 'male' ? t('form.gender.male') :
                        (pd.gender === 'female' ? t('form.gender.female') : t('form.gender.unknown')))
                    : t('form.gender.male'),
                firstName: pd.firstName ? pd.firstName : '',
                lastName: pd.lastName ? pd.lastName : '',
                dob: pd.dob ? moment(pd.dob, 'DD.MM.YYYY').toDate() : null,
                street: pd.street ? pd.street : '',
                streetNr: pd.streetNr ? pd.streetNr : '',
                zip: pd.zip ? pd.zip : '',
                city: pd.city ? pd.city : '',
                country: pd.country ? pd.country : '',
                cell: pd.cell ? pd.cell : '',
                email: pd.email ? pd.email : '',
                tosXignup: false,
                tosWebId: false,
                userDataHasChange: false
            },
            error: {},
            modal: {
                displayFormError: false,
                displayTOU: false,
                displayUserDataHasChange: false,
                displaySpinner: false,
                displaySessionTimeouted: false,
                displayInternalError: false,
            },
            loading: false,
            apiSubmitUrl: apiSubmitUrl,
        }
    }

    setStateData = (name, value, error) => {
        this.setState({
            data: {
                ...this.state.data, [name]: value
            },
            error: {
                ...this.state.error, [name]: error
            }
        })
    }

    handleInputChange = (event) => {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        this.setStateData(target.name, value, null)
    }

    handleCellChange = (event) => {
        const value = event.target.value
        const cond = /^$|^\+\d{0,15}$/

        if (value != null && cond.test(value)) {
            this.setStateData('cell', value, null)
        }
    }

    selectCountry = (country) => this.setStateData('country', country, null)

    handleDateChange = (date) => this.setStateData('dob', date, null)

    handleDateChangeRaw = (rawDate) => {
        let validatedDate = moment(rawDate, 'dd.MM.yyyy', true)
        if(validatedDate.isValid()) {
            this.handleDateChange(validatedDate)
        } else {
            this.setStateData('dob', null, this.props.t('form.dob.invalid'))
        }
    }

    handleSubmit = (event, userConfirmed) => {

        this.setState({
            loading: true
        })

        //let data = JSON.stringify(this.states.data)
        let stateData = Object.assign({}, this.state.data)
        if (this.state.data.dob) {
            stateData.dob = this.myDateString(this.state.data.dob)
        }
        if (userConfirmed === true) {
            stateData.userDataHasChange = true
        }

        stateData.agb = this.state.data.tosWebId && this.state.data.tosXignup

        console.log('hello to be determined')

        axios.post(this.state.apiSubmitUrl, JSON.stringify(stateData), {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
        }).then(success => this.handleSubmitSuccess(success.data)
        ).catch(error => this.handleFormError(error.response))

        event ? event.preventDefault() : null
    }

    myDateString = (inputDate) => {
        let dd = String(inputDate.getDate()).padStart(2, '0');
        let mm = String(inputDate.getMonth() + 1).padStart(2, '0');
        let yyyy = inputDate.getFullYear();
        return dd + '.' + mm + '.' + yyyy;
    }

    handleReset = () => {
        let clearedData = this.props.data
        clearedData.prefilledUserData = '{}'
        this.setState(IdentificationForm.getInitialState(this.props.t, clearedData))
    }

    handleSubmitSuccess = (redirectURL) => {
        console.log('hello success')
        this.setState({
            loading: false
        })
        window.location.replace(redirectURL)
    }

    handleFormError = (response) => {
        console.log('hello failure')
        this.setState({
            loading: false
        })

        const errorObject = response.data;
        let error = errorObject.errors
        if (errorObject.type === 'formValues') {
            this.setState({
                error,
                modal: {
                    ...this.state.modal, displayFormError: true
                }
            })
        } else if (errorObject.type === 'formFieldMatching') {
            this.setState({
                modal: {
                    ...this.state.modal, displayUserDataHasChange: true
                }
            })
        } else if (errorObject.type === 'agbConfirm') {
            this.setState({
                modal: {
                    ...this.state.modal, displayTOU: true
                }
            })
        } else if (response.status === 403) {
            this.setState({
                modal: {
                    ...this.state.modal, displaySessionTimeouted: true
                }
            })
        } else {
            this.setState({
                modal: {
                    ...this.state.modal, displayInternalError: true
                }
            })
        }
    }

    closeModal = (target) => {
        this.setState({
            modal: {
                ...this.state.modal, [target]: false
            },
        })
    }


    confirmFormFieldsMatching = () => {
        this.setState({
            data: {
                ...this.state.data, userDataHasChange: true
            },
            modal: {
                ...this.state.modal, displayUserDataHasChange: false
            },
        })
        this.handleSubmit(null, true)
    }


    render() {
        const {t, i18n} = this.props
        const maxDate = new Date()
        maxDate.setMonth(maxDate.getMonth() - 12*(18))

        const modalHolder = (
            <React.Fragment>
                {this.state.modal.displayFormError &&
                <FormErrorModal handleClose={(() => this.closeModal('displayFormError'))}/>
                }

                {this.state.modal.displayTOU &&
                <TOUModal handleClose={(() => this.closeModal('displayTOU'))}/>
                }

                {this.state.modal.displayUserDataHasChange &&
                <FormFieldMatchingModal
                    handleClose={(() => this.closeModal('displayUserDataHasChange'))}
                    handleConfirm={(() => this.confirmFormFieldsMatching())}/>
                }

                {this.state.modal.displaySpinner &&
                <FormErrorModal handleClose={(() => this.closeModal('displaySpinner'))}/>
                }

                {this.state.modal.displaySessionTimeouted &&
                <SessionTimeoutedModal handleClose={(() => this.closeModal('displaySessionTimeouted'))}/>
                }

                {this.state.modal.displayInternalError &&
                <InternalErrorModal handleClose={(() => this.closeModal('displayInternalError'))}/>
                }
            </React.Fragment>
        )

        const firstName = (
            <FormInput
                name='firstName'
                placeholder={t('form.firstName')}
                autoComplete='given-name'
                value={this.state.data.firstName}
                error={this.state.error.firstName}
                handleInputChange={(event) => this.handleInputChange(event)}/>
        )

        const lastName = (
            <FormInput
                name='lastName'
                placeholder={t('form.lastName')}
                autoComplete='family-name'
                value={this.state.data.lastName}
                error={this.state.error.lastName}
                handleInputChange={(event) => this.handleInputChange(event)}
            />
        )

        const street = (
            <FormInput
                name='street'
                placeholder={t('form.street.name')}
                autoComplete='address-line1'
                value={this.state.data.street}
                error={this.state.error.street}
                handleInputChange={(event) => this.handleInputChange(event)}/>
        )

        const streetNr = (
            <FormInput
                name='streetNr'
                placeholder={t('form.street.nr')}
                autoComplete='address-line2'
                value={this.state.data.streetNr}
                error={this.state.error.streetNr}
                handleInputChange={(event) => this.handleInputChange(event)}/>
        )

        const zip = (
            <FormInput
                name='zip'
                placeholder={t('form.zip')}
                autoComplete='postal-code'
                value={this.state.data.zip}
                error={this.state.error.zip}
                handleInputChange={(event) => this.handleInputChange(event)}/>
        )

        const city = (
            <FormInput
                name='city'
                placeholder={t('form.city')}
                autoComplete='address-level2'
                value={this.state.data.city}
                error={this.state.error.city}
                handleInputChange={(event) => this.handleInputChange(event)}/>
        )

        const countryPicker = (
            <CountryDropdown
                name='country'
                value={this.state.data.country}
                valueType='short'
                classes='input'
                defaultOptionLabel={t('form.country')}
                onChange={(val) => this.selectCountry(val)}
            />
        )



        const dob = (
            <DatePicker
                name='dob'
                className={'input ' + (this.state.error.dob ? 'is-danger' : '')}
                selected={this.state.data.dob}
                onChange={this.handleDateChange}
                onChangeRaw={(event) => this.handleDateChangeRaw(event.target.value)}
                showYearDropdown
                dateFormatCalendar='MMMM'
                scrollableYearDropdown
                placeholderText={t('form.dob.long')}
                dateFormat='dd.MM.yyyy'
                locale={i18n.language}
                yearDropdownItemNumber={60}
                maxDate={maxDate}
                fixedHeight
                disabledKeyboardNavigation={true}
                shouldCloseOnSelect={false}/>
        )

        const cell = (
            <FormInput
                name='cell'
                placeholder={t('form.cell.long')}
                autoComplete='tel-national'
                value={this.state.data.cell}
                error={this.state.error.cell}
                handleInputChange={(event) => this.handleCellChange(event)}/>
        )

        const email = (
            <FormInput
                name='email'
                placeholder={t('form.email.long')}
                autoComplete='email'
                value={this.state.data.email}
                error={this.state.error.email}
                handleInputChange={(event) => this.handleInputChange(event)}/>
        )

        const touCheckboxXignUp = (
            <div className='b-checkbox is-primary'>
                <input id='checkboxXignup' className='styled' type='checkbox' name='tosXignup'
                       onClick={this.handleInputChange}>
                </input>
                <label className='tos-label' htmlFor='checkboxXignup'>
                    <span className='tos-label-start'>{t('form.legalXignup.label.start')}</span>
                    <a href='/tou' id='data-link' target='_blank' rel='nofollow'>
                        <i className='fa fa-file-text-o' aria-hidden='true'/>
                        <span>{t('form.legalXignup.label.touText')}</span>
                    </a>
                    und
                    <a href='/privacypolicy' id='data-link' target='_blank' rel='nofollow'>
                        <i className='fa fa-file-text-o' aria-hidden='true'/>
                        <span>{t('form.legalXignup.label.privacyPolicy')}</span>
                    </a>
                    <span>{t('form.legalXignup.label.end')}</span>
                </label>

            </div>
        )

        const touCheckboxWebId = (
            <div className='b-checkbox is-primary'>
                <input id='checkboxWebId' className='styled' type='checkbox' name='tosWebId'
                       onClick={this.handleInputChange}>
                </input>
                <label className='tos-label' htmlFor='checkboxWebId'>
                    <span className='tos-label-start'>{t('form.legalWebId.label.start')}</span>
                    <a href={t('form.legalWebId.links.tou')} id='data-link' target='_blank' rel='nofollow'>
                        <i className='fa fa-file-text-o' aria-hidden='true'/>
                        <span>{t('form.legalWebId.label.touText')}</span>
                    </a>
                    <a href={t('form.legalWebId.links.privacyPolicy')} id='data-link' target='_blank' rel='nofollow'>
                        <i className='fa fa-file-text-o' aria-hidden='true'/>
                        <span>{t('form.legalWebId.label.privacyPolicy')}</span>
                    </a>
                    und
                    <a href={t('form.legalWebId.links.declarationOfConsent')} id='data-link' target='_blank' rel='nofollow'>
                        <i className='fa fa-file-text-o' aria-hidden='true'/>
                        <span>{t('form.legalWebId.label.declarationOfConsent')}</span>
                    </a>
                    <span>{t('form.legalWebId.label.end')}</span>
                </label>

            </div>
        )

        const submitButton = (
            <div className='control'>
                <button type='submit'
                        title={!(this.state.data.tosWebId && this.state.data.tosXignup) ? t('form.submit.hint') : null}
                        className={'button is-primary ' + (this.state.loading ? 'is-loading' : '')}
                        onClick={this.handleSubmit} disabled={this.state.loading || !(this.state.data.tosWebId && this.state.data.tosXignup)} >
                                <span className='icon is-small'>
                                    <i className='fa fa-check-circle'/>
                                </span>
                    <span>{t('form.submit.label')}</span>
                </button>

            </div>
        )

        const resetButton = (
            <div className='control'>
                <button type='reset' className='button' onClick={this.handleReset}>
                                <span className='icon is-small'>
                                    <i className='fa fa-times'/>
                                </span>
                    <span>{t('form.reset')}</span>
                </button>
            </div>
        )

        return (
            <form name='identification-data-form' onSubmit={this.handleSubmit}>

                {modalHolder}

                <div className='is-divider'
                     data-content={t('form.personalInfo')}/>
                <div className='field is-horizontal'>
                    <div className='field-label is-normal'>
                        <label className='label'>{t('form.gender.label')}</label>
                    </div>
                    <div className='field-body limit-field-dimension'>
                        <div className='field is-grouped is-grouped-multiline'>
                            <div className='control'>
                                <div className='select'>
                                    <select name='gender' onChange={this.handleInputChange}
                                            value={this.state.data.gender}>
                                        <option value={t('form.gender.male')}>{t('form.gender.male')}</option>
                                        <option value={t('form.gender.female')}>{t('form.gender.female')}</option>
                                        <option value={t('form.gender.unknown')}>{t('form.gender.unknown')}</option>
                                    </select>
                                </div>

                            </div>
                            {firstName}
                            {lastName}
                        </div>
                    </div>
                </div>
                <div className='field is-horizontal'>
                    <div className='field-label is-normal'>
                        <label className='label'>{t('form.address')}</label>
                    </div>
                    <div className='field-body limit-field-dimension'>
                        <div className='field'>
                            {street}
                        </div>
                        <div className='field'>
                            {streetNr}
                        </div>
                    </div>
                </div>

                <div className='field is-horizontal'>
                    <div className='field-label'/>
                    <div className='field-body limit-field-dimension'>
                        <div className='field is-expanded'>
                            {zip}
                        </div>
                        <div className='field is-expanded'>
                            {city}
                        </div>
                        <div className='control'>
                            <div className='select'>
                                {countryPicker}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='field is-horizontal'>
                    <div className='field-label is-normal'>
                        <label className='label'>{t('form.dob.short')}</label>
                    </div>
                    <div className='field-body limit-field-dimension'>
                        <div className='field is-narrow dobField'>
                            {dob}

                            {this.state.error.dob &&
                            <p className='help is-danger has-text-left'>{this.state.error.dob}</p>
                            }
                        </div>
                    </div>
                </div>

                <div className='field is-horizontal'>
                    <div className='field-label'>
                        <label className='label'>{t('form.cell.short')}</label>
                    </div>
                    <div className='field-body limit-field-dimension'>
                        <div className='field is-expanded'>
                            {cell}
                        </div>
                    </div>
                </div>

                <div className='field is-horizontal'>
                    <div className='field-label'>
                        <label className='label'>{t('form.email.short')}</label>
                    </div>
                    <div className='field-body limit-field-dimension'>
                        <div className='field is-expanded'>
                            {email}
                        </div>
                    </div>
                </div>

                <div className='is-divider' data-content={t('form.legalInfoXignup')}/>

                <div className='field is-horizontal'>
                    <div className='field-label'>
                        <label className='label'/>
                    </div>
                    <div className='field-body limit-field-dimension'>
                        <div className='field is-expanded checkbox-field'>
                            {touCheckboxXignUp}
                        </div>
                    </div>
                </div>

                <div className='is-divider' data-content={t('form.legalInfoWebId')}/>

                <div className='field is-horizontal'>
                    <div className='field-label'>
                        <label className='label'/>
                    </div>
                    <div className='field-body limit-field-dimension'>
                        <div className='field is-expanded checkbox-field'>
                            {touCheckboxWebId}
                        </div>
                    </div>
                </div>
                <FormButtonBar>{submitButton}{resetButton}</FormButtonBar>
            </form>
        )
    }
}

// extended main view with translate hoc
export default withTranslation('translations')(IdentificationForm)