import React from 'react'
import ReactDOM from 'react-dom'
import BaseModal from './BaseModal.jsx'
import {withTranslation} from 'react-i18next'

const modalRoot = document.getElementById('dynamic-modal-root')

class CenteredModal extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        const {title, body, handleClose} = this.props

        let infoModal = (
            <BaseModal
                textClasses='has-text-centered'
                headerTitle={title}
                body={body}
                handleClose={handleClose}/>
        )

        return (
            ReactDOM.createPortal(
                infoModal,
                modalRoot,
            )
        )
    }
}

export default withTranslation('translations')(CenteredModal)