export let setupLanguageButtons = (languageButtonList) => {
    let toggle = document.getElementById('language-select-toggle')
    let toggleText = document.getElementById('language-select-toggle-text')
    let dropdownItem = document.getElementById('language-select-dropdown')

    toggle.addEventListener('click', () => {
        if(dropdownItem.classList.contains('language-select-active')) {
            dropdownItem.classList.toggle('is-active')
        }
    })

    for(let languageButton of languageButtonList) {
        setupLanguageButton(languageButton, toggle, toggleText, dropdownItem)
    }
}

export let setupLanguageButton = (languageButton, toggle, toggleText, dropdownItem) => {

    let element = document.getElementById(languageButton)
    element.addEventListener('click', () => {
        let txt = document.createTextNode(element.dataset.language)
        if(toggleText.hasChildNodes()) {
            toggleText.removeChild(toggleText.lastChild)
        }
        toggleText.appendChild(txt)

        dropdownItem.classList.toggle('is-active')
        let newLocale = element.dataset.language

        let xhr = new XMLHttpRequest()

        xhr.open('GET', '/set_locale?xi-lang=' + newLocale)
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
        xhr.onload = function() {
            if (xhr.status === 200) {
               location.reload()
            }
            else if (xhr.status !== 200) {
               //alert('Request failed.  Returned status of ' + xhr.status)
            }
        }
        xhr.send()

    })

}

