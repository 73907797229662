import React from 'react'
import ReactDOM from 'react-dom'
import BaseModal from './BaseModal.jsx'
import {withTranslation} from 'react-i18next'

const modalRoot = document.getElementById('dynamic-modal-root')

class TanErrorModal extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        const {t, i18n} = this.props

        let infoModal = (
            <BaseModal
                headerTitle={t('modal.tan.headerTitle')}
                headerSubtitle={t('modal.tan.headerSubtitle')}
                body={t('modal.tan.body')}
                handleClose={this.props.handleClose}/>
        )

        return (
            ReactDOM.createPortal(
                infoModal,
                modalRoot,
            )
        )
    }
}

export default withTranslation('translations')(TanErrorModal)