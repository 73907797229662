import React from 'react'
import {withTranslation} from 'react-i18next'
import axios from 'axios/index'
import {Button, FormInput, FormRow} from './../common/Forms.jsx'
import UserComponent from "./UserComponent.jsx";
import CenteredModal from './../modal/CenteredModal.jsx'

const newUserId = -1

class UserAdministration extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            users: [],
            error: {},
            userSnapshot: {},
            loadingSubmit: false,
            modal: {
                emailResetSent: false,
                emailDocumentationSent: false
            }
        }
    }

    componentDidMount() {
        this.loadUsers()
    }

    loadUsers = () => {
        this.setState({
            loadingSubmit: true
        })
        axios.get(this.props.getUsersUrl, {
            withCredentials: true
        }).then(success => {
            this.setState({
                users: success.data,
                loadingSubmit: false
            })
        }).catch(error => {
            this.setState({
                error: error.reponse.data,
                loadingSubmit: false
            })
        })
    }

    postUser = (id) => {
        this.setState({
            loadingSubmit: true
        })
        let user = this.state.users.find(user => user.id === id)
        axios.post(user.id ===  newUserId ? this.props.newUserUrl : this.props.updateUserUrl, JSON.stringify(user), {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true
        }).then(success => {
            this.setState(prevState => ({
                users: prevState.users.map(user => (user.id === id ?
                    {...user, ...success.data, activeEdit: false} :
                    user)),
                loadingSubmit: false
            }))
        }).catch(error => {
            if(error.response.status === 409) {
                this.setState({
                    error: {
                        ...this.state.error, ['username']: this.props.t('admin.customer.error.conflict')
                    },
                    loadingSubmit: false
                })
            }
            else {
                this.setState({
                    error: error.reponse.data,
                    loadingSubmit: false
                })
            }
        })
    }


    postDeleteUser = (id) => {
        axios.get(this.props.deleteUserUrl + id, {
            withCredentials: true
        }).then(() => this.removeUser(id)
        ).catch(f => f)
    }

    postSendResetEmail = (id) => {
        axios.get(this.props.resetUserUrl + id, {
            withCredentials: true
        }).then(() => {
            const update = {userState: 'PENDING'}
            const user = this.state.users.find(user => user.id === id)
            this.setState({
                modal: {
                    emailResetSent: true
                }
            })
            this.updateUser(id, update)
            this.updateUserSnapshot(id, update)
            }
        ).catch(f => f)
    }

    postSendDocumentationEmail = (id) => {
        axios.get(this.props.sendDocumentationUserUrl + id, {
            withCredentials: true
        }).then(success => {
            const user = this.state.users.find(user => user.id === id)
            this.setState({
                modal: {
                    emailDocumentationSent: true
                }
            })
            }
        ).catch(f => f)
    }


    handleClickNew =  () => this.addUser({activeEdit: true, id: newUserId})

    handleClickEdit = (id) => {
        this.createUserSnapshot(id)
        this.updateUser(id, {activeEdit: true})
    }

    handleClickCancel = (id) => (id !== newUserId) ? this.updateUser(id, {...this.state.userSnapshot, activeEdit: false}) : this.removeUser(id)

    handleInputChange = (event, id, validations) =>  {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
        const name = event.target.name

        let validationError = null
        if(validations.includes('isNotEmpty') && value.trim() === '') {
            validationError = this.props.t('admin.customer.error.empty')
        }
        if(validations.includes('isEmail') && !/^\S+@\S+$/.test(value)){
            validationError = this.props.t('admin.customer.error.noEmail')
        }
        this.updateUser(id, {[name]: value})
        this.setState(prevState => ({
            error: {
                ...prevState.error, [name]: validationError
            }
        }))
    }

    addUser = (newUser) => {
        this.setState(prevState => ({
            users: [...prevState.users, newUser]
        }))
    }

    removeUser = (id) => {
        this.setState(prevState => ({
            users: prevState.users.filter( user => id !== user.id)
        }));
    }

    updateUser = (id, changes) => {
        this.setState(prevState => ({
            users: prevState.users.map(user => (user.id === id ?
                {...user, ...changes} :
                user))
        } ));
    }

    createUserSnapshot = (id) => {
        this.setState(prevState => ({
            userSnapshot: prevState.users.find( user => id === user.id),
            error: {}
        }));
    }

    updateUserSnapshot = (id, changes) => {
        if (this.state.userSnapshot.id === id) {
            this.setState(prevState => ({
                userSnapshot: {...prevState.userSnapshot, ...changes}
            }))
        }
    }

    closeModal = (target) => {
        this.setState({
            modal: {
                ...this.state.modal, [target]: false
            },
        })
    }

    render() {
        const {t} = this.props

        const allowEdit = this.state.users.every( user => !user.activeEdit)

        const title = ( <h2 className='title has-text-centered is-size-4'>{t('admin.user.title')}</h2> )
        const headerTitles = [['admin.user.username.label', ''], ['admin.user.email.label', 'is-4'], ['admin.user.state.label', ''], ['admin.user.lastLogin.label'],  ['admin.user.edit.label', '']]
        const header = (
            <div className='columns has-text-centered' style={{alignItems: 'center'}}>
                { headerTitles.map(bundle => <div className={`column label ${bundle[1]}`}>{t(bundle[0])}</div>) }
            </div>
        )

        const listUsers = this.state.users.length !== 0 ?
            this.state.users.map((user, index) => (
            <UserComponent t={t} user={user} key={index} allowEdit={allowEdit} error={this.state.error}
                           handleClickCancel={this.handleClickCancel} handleClickEdit={this.handleClickEdit} handleInputChange={this.handleInputChange}
                           handleClickSubmit={this.postUser} handleClickDelete={this.postDeleteUser} handleClickResetEmail={this.postSendResetEmail}
                           handleClickDocumentationEmail={this.postSendDocumentationEmail}/>
        )) : (
            <h1 className='monitoring-table-empty is-margin-bottom'>{t('admin.user.noUsers')}</h1>
        )

        const newUserButton = (
            <Button onClick={this.handleClickNew} icon='fa-plus' text={t('admin.user.new.label')} style={{marginLeft: 'auto'}} className='column' disabled={!allowEdit}/>
        )

        const modalHolder = (
            <React.Fragment>
                {this.state.modal.emailResetSent &&
                <CenteredModal
                    title={t('admin.modal.successReset.title')}
                    body={t('admin.modal.successReset.body', {email: this.state.userSnapshot.email})}
                    handleClose={(() => this.closeModal('emailResetSent'))}/>
                }

                {this.state.modal.emailDocumentationSent &&
                <CenteredModal
                    title={t('admin.modal.successDocumentation.title')}
                    body={t('admin.modal.successDocumentation.body', {email: this.state.userSnapshot.email})}
                    handleClose={(() => this.closeModal('emailDocumentationSent'))}/>
                }
            </React.Fragment>
        )


        return (
            <React.Fragment>
                {modalHolder}
                <div className='is-divider is-margin-top'/>
                {title}
                <div className='is-margin-bottom-large'>
                    {header}
                    {listUsers}
                    {newUserButton}
                </div>
            </React.Fragment>
        )
    }
}


export default withTranslation('translations')(UserAdministration)