import React from 'react'
import {withTranslation} from 'react-i18next'
import moment from 'moment'
import {Button, FormInput, FormRow} from './../common/Forms.jsx'

class UserComponent extends React.Component {

    constructor(props) { super(props)}

    handleCancel = () => {
        this.setState({
            user: this.props.user
        },() => this.props.handleClickCancel(this.props.user.id))
    }

    render() {
        const {t, user, error,  allowEdit, handleClickEdit, handleClickSubmit, handleClickCancel, handleInputChange,
            handleClickDelete, handleClickResetEmail, handleClickDocumentationEmail} = this.props
        const usernameInput = (
            <FormInput
                name='username'
                value={user.username}
                error={error.username}
                placeholder={t('admin.user.username.placeholder')}
                handleInputChange={(event) => handleInputChange(event, user.id, ['isNotEmpty'])}/>
        )
        const emailInput = (
            <FormInput
                name='email'
                value={user.email}
                error={error.email}
                placeholder={t('admin.user.email.placeholder')}
                handleInputChange={(event) => handleInputChange(event, user.id,['isNotEmpty', 'isEmail'])}/>
        )

        const isNewUser = user.id === -1
        const isAdmin = user.admin
        const showSecondaryButtons = !isAdmin && !isNewUser
        const isActiveEdit = user.activeEdit
        const isReadOnly = !user.activeEdit

        return (
            <React.Fragment>
                { isReadOnly &&
                    <div className='columns has-text-centered has-text-grey-dark' style={{alignItems: 'center'}}>
                        <div className='column'>
                            {isAdmin &&
                            <span className='icon is-small'>
                                <i className='fa fa-star'/>
                            </span>
                            }{user.username}
                        </div>
                        <div className='column is-4'>{user.email}</div>
                        <div className='column'>{t(`admin.user.state.${user.userState}`)}</div>
                        <div className='column'>{user.lastLogin ? moment(user.lastLogin).format('DD.MM.YY, HH:mm') : t('admin.user.lastLogin.never')}</div>
                        <div className='column'>
                            <Button onClick={() => handleClickEdit(user.id)} icon='fa-edit' size='is-small' disabled={!allowEdit}/>
                            { !isAdmin &&
                                <Button onClick={() => handleClickDelete(user.id)} icon='fa-trash' size='is-small' disabled={!allowEdit}/>
                            }
                        </div>
                    </div>
                }
                { isActiveEdit &&
                    <React.Fragment>
                        { isNewUser &&
                            <div className='is-divider' data-content={t('admin.user.new.label')}/>
                        }
                        { !isNewUser &&
                            <div className='is-divider' data-content={t('admin.user.edit.title') + ' ' + user.username}/>
                        }
                        <form className='columns is-vcentered' onSubmit={e => e.preventDefault()}>
                            <div className={'column is-9'}>
                                <FormRow t={t} label={'admin.user.username.label'}>{usernameInput}</FormRow>
                                <FormRow t={t} label={'admin.user.email.label'}>{emailInput}</FormRow>
                            </div>
                            <div className={'column'} >
                                <div className='field is-horizontal has-text-centered'>
                                    <div className='field-body'>
                                        <div className={'field '}>
                                            <Button onClick={() => handleClickSubmit(user.id)} icon='fa-check' className='is-primary' text='Submit' style={{minWidth: '7rem'}}/>
                                            <Button onClick={() => handleClickCancel(user.id)} icon='fa-close'/>
                                        </div>
                                    </div>
                                </div>
                                { showSecondaryButtons &&
                                    <div className='field is-horizontal has-text-centered'>
                                        <div className='field-body'>
                                            <div className={'field '}>
                                                <Button onClick={() => handleClickResetEmail(user.id)} icon={user.userState === 'INACTIVE' ? 'fa-unlock' : 'fa-lock'} text={user.userState === 'INACTIVE' ?'Activate' : 'Reset' } style={{minWidth: '7rem'}}/>
                                                <Button onClick={() => handleClickDocumentationEmail(user.id)} icon='fa-envelope'/>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </form>
                        <div className='is-divider'/>
                    </React.Fragment>
                }
            </React.Fragment>


        )
    }
}

export default withTranslation('translations')(UserComponent)