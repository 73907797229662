import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(LanguageDetector)
    .init({
        // start language detector config
        detection: {
            order: ['cookie'],
            lookupCookie: 'user-locale'
        },

        // we init with resources
        resources: {
            en: {
                translations: {
                    tan: {
                        placeholder: 'Enter 6-digit TAN',
                        button: 'Confirm TAN'
                    },
                    form: {
                        title: 'Start identification',
                        name: 'Name',
                        degree: 'Title, academic degree',
                        subtitle: 'Please fill out the form below with your personal information and keep your ID card ready',
                        personalInfo: 'Personal data',
                        legalInfoXignup: 'Legal information - Xign Up',
                        legalInfoWebId: 'Legal information - Partner: WebID',
                        gender: {
                            label: 'Gender',
                            female: 'Mrs.',
                            male: 'Mr.',
                            unknown: 'Mx.'
                        },
                        firstName: 'Firstname',
                        lastName: 'Lastname',
                        address: 'Address',
                        city: 'City',
                        country: 'Select country',
                        dob: {
                            short: 'Date of birth',
                            long: 'Date of birth',
                            invalid: 'Invalid date: format required - DD.MM.YYYY',
                        },
                        street: {
                            name: 'Street',
                            nr: 'Street Nr.',
                        },
                        cell: {
                            short: 'Mobile phone',
                            long: 'Number with country code, format: +491234... ',
                        },
                        zip: 'Zip code',
                        email: {
                            short: 'E-Mail',
                            long: 'E-Mail address'
                        },
                        legalXignup: {
                            name: 'GTC',
                            label: {
                                start: 'I have read the',
                                end: 'and accept them',
                                touText: 'GTC',
                                privacyPolicy: 'Privacy Policy'
                            }
                        },
                        legalWebId: {
                            name: 'GTC',
                            label: {
                                start: 'I have read the',
                                end: 'of WebID and accept them',
                                touText: 'T&Cs,',
                                privacyPolicy: 'Privacy Policy',
                                declarationOfConsent: 'Declaration of Consent'
                            },
                            links: {
                                tou: 'https://www.webid-solutions.de/en/terms-and-conditions/',
                                privacyPolicy: 'https://www.webid-solutions.de/en/privacy-policy/',
                                declarationOfConsent: 'https://www.webid-solutions.de/en/declaration-of-consent/',
                            }
                        },
                        submit: {
                            label: 'Submit data',
                            hint: 'Please accept the TOS'
                        },
                        reset: 'Reset',
                    },
                    modal: {
                        formFieldsMatching: {
                            headerTitle: 'Already registered',
                            headerSubtitle: 'Confirmation required',
                            body: "You've been previously registered with webID. Please confirm if you still want to continue and change your data",
                            confirm: 'Proceed',
                            abort: 'Abort'
                        },
                        tou: {
                            headerTitle: 'GTC',
                            headerSubtitle: 'Missing confirmation',
                            body: 'Please read and agree to our GTC to continue'
                        },
                        formError: {
                            headerTitle: 'Error',
                            headerSubtitle: 'Insufficient information provided',
                            body: 'Please check your submission for errors or missing fields'
                        },
                        tan: {
                            headerTitle: 'Error',
                            headerSubtitle: 'Invalid TAN entered',
                            body: 'Please validate your entry and correct it'
                        },
                        timeout: {
                            headerTitle: 'Error',
                            headerSubtitle: 'Session timeouted',
                            body: "You've been inactive for too long. Please restart the registration process."
                        },
                        internal: {
                            headerTitle: 'Error',
                            headerSubtitle: 'Unxepected Communication problem',
                            body: 'We cannot reach the server of our partner or process their response. Please try again later or contact our support.'
                        }
                    },
                    monitoring: {
                        all: 'All',
                        identificationState: {
                            success: 'Success',
                            failure: 'Failure',
                            timeout: 'Timeout',
                        },
                        certificateState: {
                            certificateIssued: 'Active',
                            noCertificate: 'Inactive'
                        },
                        filterError: 'Invalid date',
                        idProvider: {
                            webId: 'Web ID',
                            authData: 'AuthData',
                            nect: 'Nect',
                        },
                        label: {
                            dateSessionStart: {
                                short: 'Start Date',
                                long: 'Session start date',
                            },
                            dateIdentification: {
                                short: 'ID Date',
                                long: 'Identification date',
                            },
                            dateCertActivation: {
                                short: 'CA Date',
                                long: 'Certificate activation date',
                            },
                            name: 'Name',
                            email: 'E-Mail',
                            identificationState: {
                                short: 'ID Stat.',
                                long: 'Identification Status',
                            },
                            certificateState: {
                                short: 'Cert Stat.',
                                long: 'Certificate State',
                            },
                            actionId: {
                                short: 'Act. ID',
                                long: 'Action ID',
                            },
                            provider: {
                                short: 'Provider',
                                long: 'Identity Provider',
                            },
                            secondaryId: {
                                short: 'Add. ID',
                                long: 'Additional Identification',
                            },
                            customer: 'Customer',
                            startDate: 'Date from:',
                            endDate: 'Date to:',
                            identification: 'Identification',
                            certificate: 'Certificate',
                            today: 'Current Date',
                            doFilter: 'Apply Filter',
                            doDownloadCsvLabel: 'Generate report as .CSV',
                            doDownloadCsv: 'Download',
                        },
                        subsectionFilter: 'Select Filter',
                        subsectionResult: 'Results',
                        subsectionExport: 'Export results',
                        noResultFound: 'No Results found',
                        linkgen: {
                            title: 'Create dynamic ticket links',
                            new: 'Generate new',
                            noLink: 'You have not generated any new links yet',
                        },
                        modal: {
                            error: {
                                title: 'Error generating link',
                                body: 'An error occured while generating the link.',
                            },
                        },
                    },
                    cookiePolicy: {
                        message: 'Our site requires the use of cookies. By continuing to use our site you consent to the use of cookies.',
                        button: 'OK',
                        link: 'more information'
                    },
                    password: {
                        change: {
                            firstLabel: 'Enter new password',
                            secondLabel: 'Enter password again',
                            buttonText: 'Change Password',
                            notMatching: 'Passwords do not match'
                        },
                        modal: {
                            success:{
                                title: 'Change Successful',
                                body: 'Please use your new password to login in the future'
                            },
                            error:{
                                title: 'Change Failed',
                                body: 'Your password has not been changed'
                            }
                        }
                    },
                    admin: {
                        customer: {
                            title: 'Update Customer:',
                            error: {
                                empty: 'Must not be empty',
                                noUuid: 'Must be a V4 UUID',
                                conflict: 'Name already exists',
                                noEmail: 'Not an e-mail'
                            },
                            customerName: {
                                label: 'Name'
                            },
                            registrationLimit: {
                                label: 'Registration Limit'
                            },
                            identityProvider: {
                                label: 'Identity Provider'
                            },
                            customerApiKey: {
                                label: 'Static Cust. Key',
                                warning: 'Discouraged, prefer ticket API'
                            },
                            customerTicketApiKey: {
                                label: 'Ticket API Key'
                            },
                            theme: {
                                label: 'Theme',
                                defaultText: 'Default theme'
                            },
                            poweredByMsgBundle : {
                                label: 'Powered By Msg',
                                defaultText: 'No custom powered by message bundle'
                            },
                            generateUuid: 'Generate new',
                            button: {
                                back: 'Back to Overview',
                                submitChanges: 'Submit Changes',
                                deleteCustomer: 'Delete'
                            }
                        },
                        user: {
                            noUsers:'No administrative users found',
                            new: {
                                label: "New User"
                            },
                            edit: {
                                title: "Manage user",
                                label: "Edit"
                            },
                            title: "Manage administrative users",
                            username: {
                                label: 'Login name',
                                placeholder: 'Choose new login name'
                            },
                            email: {
                                label: 'E-Mail',
                                placeholder: 'Enter E-Mail address'
                            },
                            lastLogin: {
                                label: 'Last Login',
                                never: 'Never'
                            },
                            tooltip: {
                                api: 'Sends an API information E-Mail to the user',
                                resetPassword: 'Sends Password reset E-Mail to the user',
                                cancelChange: 'Aborts all changes'
                            },
                            state: {
                                label: 'State',
                                null: 'Null',
                                INACTIVE: 'Inactive',
                                PENDING: 'Pending PW',
                                ACTIVE: 'Active',
                                LOCKED: 'Locked'
                            }
                        },
                        modal: {
                            success:{
                                title: 'Changes successfull',
                                body: 'Your changes have been applied'
                            },
                            error:{
                                title: 'Changes failed',
                                body: 'Your data has not been changed'
                            },
                            successReset:{
                                title: 'E-Mail Sent',
                                body: 'Password has been reset. An E-Mail with instructions to set a new one was sent to {{email}}'
                            },
                            successDocumentation:{
                                title: 'E-Mail Sent',
                                body: 'An E-Mail with API Information and personalized examples was sent to {{email}}'
                            },
                            copy:{
                                title: 'Clipboard',
                                body: '{{text}} has been copied to your clipboard'
                            }
                        }
                    }
                }
            },
            de: {
                translations: {
                    tan: {
                        placeholder: 'Eingabe TAN (6 stellig)',
                        button: 'Bestätige TAN'
                    },
                    form: {
                        title: 'Identifikation starten',
                        name: 'Anrede',
                        degree: 'Titel, akad.Grad',
                        subtitle: 'Bitte vervollständigen Sie das Formular und halten Sie ihren Ausweis bereit',
                        personalInfo: 'Persönliche Daten',
                        legalInfoXignup: 'Rechtliche Informationen - Xign Up',
                        legalInfoWebId: 'Rechtliche Informationen - Partner: WebID',
                        gender: {
                            label: 'Anrede',
                            female: 'Frau',
                            male: 'Herr',
                            unknown: 'Person'
                        },
                        firstName: 'Vorname',
                        lastName: 'Nachname',
                        address: 'Adresse',
                        city: 'Stadt',
                        country: 'Land auswählen',
                        dob: {
                            short: 'Geb. Datum',
                            long: 'Geburtsdatum',
                            invalid: 'Ungültige Eingabe: erforderliches Format - DD.MM.YYYY',
                        },
                        street: {
                            name: 'Straße',
                            nr: 'Hausnummer',
                        },
                        cell: {
                            short: 'Mobiltelefon',
                            long: 'Nummer mit Ländervorwahl im Format +491234...',
                        },
                        zip: 'Postleitzahl',
                        email: {
                            short: 'E-Mail',
                            long: 'E-Mail Adresse'
                        },
                        legalXignup: {
                            name: 'AGB',
                            label: {
                                start: 'Ich habe die',
                                end: 'gelesen und akzeptiere Sie',
                                touText: 'AGB',
                                privacyPolicy: 'Datenschutzerklärung'
                            }
                        },
                        legalWebId: {
                            name: 'TOU',
                            label: {
                                start: 'Ich habe die',
                                end: 'der WebID gelesen und akzeptiere Sie',
                                touText: 'AGB,',
                                privacyPolicy: 'Datenschutzerklärung',
                                declarationOfConsent: 'Einwilligungserklärung'
                            },
                            links: {
                                tou: 'https://www.webid-solutions.de/de/terms-and-conditions/',
                                privacyPolicy: 'https://www.webid-solutions.de/de/privacy-policy/',
                                declarationOfConsent: 'https://www.webid-solutions.de/de/declaration-of-consent/',
                            }
                        },
                        submit: {
                            label: 'Bestätige Daten',
                            hint: 'Bitte stimmen Sie den AGB zu'
                        },
                        reset: 'Zurücksetzen'
                    },

                    modal: {
                        formFieldsMatching: {
                            headerTitle: 'Bereits registriert',
                            headerSubtitle: 'Bestätigung erforderlich',
                            body: 'Sie sind bereits mit diesen Daten bei der webID Registriert. Bitte stimmen Sie zu um trotzdem fortzufahren und ihre Daten zu ändern',
                            confirm: 'Fortfahren',
                            abort: 'Abbrechen'
                        },
                        tou: {
                            headerTitle: 'AGB',
                            headerSubtitle: 'Zustimmung erforderlich',
                            body: 'Bitte lesen und akzeptieren Sie unseren AGB zu um fortzufahren'
                        },
                        formError: {
                            headerTitle: 'Fehler',
                            headerSubtitle: 'Unvollständige Dateneingabe',
                            body: 'Bitte überprüfen Sie ihre Eingabe auf fehlerhafte oder leere Eingaben'
                        },
                        tan: {
                            headerTitle: 'Fehler',
                            headerSubtitle: 'Ungültiger TAN eingegeben',
                            body: 'Bitte überprüfen Sie ihre Eingabe und korrigieren Sie diese'
                        },
                        timeout: {
                            headerTitle: 'Fehler',
                            headerSubtitle: 'Session abgelaufen',
                            body: 'Sie wurden wegen Inaktivität abgemeldet. Bitte starten Sie den Registrierungsvorgang erneut.'
                        },
                        internal: {
                            headerTitle: 'Fehler',
                            headerSubtitle: 'Unerwartetes Kommunikations-Problem',
                            body: 'Wir können die Server unserer Partner nicht erreichen oder die Antwort nicht verarbeiten. Bitte versuchen Sie es später erneut oder kontaktieren Sie unseren Support.'
                        },
                        submit: {

                        }
                    },
                    monitoring: {
                        all: 'Alle',
                        identificationState: {
                            success: 'Erfolg',
                            failure: 'Fehler',
                            timeout: 'Timeout',
                        },
                        certificateState: {
                            certificateIssued: 'Aktiv',
                            noCertificate: 'Inaktiv'
                        },
                        filterError: 'Ungültiges Datum',
                        idProvider: {
                            webId: 'Web ID',
                            authData: 'AuthData',
                            nect: 'Nect',
                        },
                        label: {
                            dateSessionStart: {
                                short: 'Start Datum',
                                long: 'Session Start Datum',
                            },
                            dateIdentification: {
                                short: 'ID Datum',
                                long: 'Datum der Identifikation',
                            },
                            dateCertActivation: {
                                short: 'ZA Datum',
                                long: 'Datum der Zertifikatsaktivierung',
                            },
                            name: 'Name',
                            email: 'E-Mail',
                            identificationState: {
                                short: 'ID Stat.',
                                long: 'Identifikations-Status',
                            },
                            certificateState: {
                                short: 'Zert Stat.',
                                long: 'Zertifikats-Status',
                            },
                            actionId: {
                                short: 'Vorg. Nr.',
                                long: 'Vorgangsnummer',
                            },
                            provider: {
                                short: 'Anbieter',
                                long: 'Identitätsanbieter',
                            },
                            secondaryId: {
                                short: 'Weitere ID',
                                long: 'Weitere Identifikationskennzahl',
                            },
                            customer: 'Kunde',
                            startDate: 'Datum von:',
                            endDate: 'Datum bis:',
                            identification: 'Identifikation',
                            certificate: 'Zertifikat',
                            today: 'Aktuelles Datum',
                            doFilter: 'Filter anwenden',
                            doDownloadCsvLabel: 'Generiere Report als .CSV',
                            doDownloadCsv: 'Herunterladen',
                            action: 'Aktion',
                        },
                        subsectionFilter: 'Filter auswählen',
                        subsectionResult: 'Ergebnisse',
                        subsectionExport: 'Ergebnis exportieren',
                        noResultFound: 'Keine Ergebnisse vorhanden',
                        linkgen: {
                            title: 'Dynamische Ticket Links erzeugen',
                            new: 'Neu generieren',
                            noLink: 'Sie haben noch keine Links generiert',
                        },
                        modal: {
                            error: {
                                title: 'Generieren des Links fehlgeschlagen',
                                body: 'Bei der Generierung des Links ist ein fehler aufgetreten.',
                            },
                        },
                    },
                    cookiePolicy: {
                        message: 'Cookies helfen uns bei der Erbringung unserer Dienste. Durch die Nutzung unserer Webseite erklären Sie sich mit dem Setzen von Cookies einverstanden.',
                        button: 'OK',
                        link: 'weitere Informationen'
                    },
                    password: {
                        change: {
                            firstLabel: 'Neues Passwort eingeben',
                            secondLabel: 'Passwort erneut eingeben',
                            buttonText: 'Passwort ändern',
                            notMatching: 'Passwörter stimmen nicht überein'
                        },
                        modal: {
                            success:{
                                title: 'Änderung erfolgreich',
                                body: 'Bitte nutzen Sie in Zukunft ihr neues Passwort'
                            },
                            error:{
                                title: 'Änderung fehlgeschlagen',
                                body: 'Ihr altes Passwort wurde nicht geändert'
                            }
                        }
                    },
                    admin: {
                        customer: {
                            title: 'Kunde ändern:',
                            error: {
                                empty: 'Darf nicht leer sein',
                                noUuid: 'Keine V4 UUID',
                                conflict: 'Name bereits vergeben'
                            },
                            customerName: {
                                label: 'Name'
                            },
                            registrationLimit: {
                                label: 'Registrierungslimit'
                            },
                            identityProvider: {
                                label: 'Identitätsaussteller'
                            },
                            customerApiKey: {
                                label: 'Static Cust. Key',
                                warning: 'Sollte vermieden werden'
                            },
                            customerTicketApiKey: {
                                label: 'Ticket API Key'
                            },
                            theme: {
                                label: 'Theme',
                                defaultText: 'Standard Theme'
                            },
                            poweredByMsgBundle : {
                                label: 'Powered By Msg',
                                defaultText: 'Standard powered by Nachricht'
                            },
                            generateUuid: 'Neu generieren',
                            button: {
                                back: 'Zurück zur Übersicht',
                                submitChanges: 'Änderungen bestätigen',
                                deleteCustomer: 'Löschen'
                            }
                        },
                        adminUser: {
                            loginName: {
                                label: 'Login Name'
                            },
                            password: {
                                label: 'Password'
                            }
                        },
                        user: {
                            noUsers:'Keine administrativen Benuter gefunden',
                            new: {
                                label: "Neuer Benutzer"
                            },
                            edit: {
                                title: "Verwalte Benutzer",
                                label: "Verwalten"
                            },
                            title: "Administrative Benutzer verwalten",
                            username: {
                                label: 'Login name',
                                placeholder: 'Neuen Login Namen wählen'
                            },
                            email: {
                                label: 'E-Mail',
                                placeholder: 'E-Mail Adresse eingeben'
                            },
                            lastLogin: {
                                label: 'Login',
                                never: 'Nie'
                            },
                            tooltip: {
                                api: 'Sendet eine E-Mail mit API Information an den Benutzer',
                                resetPassword: 'Setzt das Password zurück und sendet E-Mail an Benutzer',
                                cancelChange: 'Alle Änderungen abbrechen'
                            },
                            state: {
                                label: 'Status',
                                null: 'Null',
                                INACTIVE: 'Inaktiv',
                                PENDING: 'Offen',
                                ACTIVE: 'Aktiv',
                                LOCKED: 'Gesperrt'
                            }
                        },
                        modal: {
                            success:{
                                title: 'Änderung erfolgreich',
                                body: 'Die geänderten Daten wurden übernommen'
                            },
                            error:{
                                title: 'Änderung fehlgeschlagen',
                                body: 'Die Daten wurden nicht verändert'
                            },
                            successReset:{
                                title: 'E-Mail Gesendet',
                                body: 'Das Passwort wurde zurückgesetzt. Eine E-Mail mit Anweisungen zum Setzen eines neuen wurde an {{email}} gesendet'
                            },
                            successDocumentation:{
                                title: 'E-Mail Gesendet',
                                body: 'Eine E-Mail mit Informationen zur API und personalisierten Beispielen wurde an {{email}} gesendet'
                            },
                            copy:{
                                title: 'Zwischenablage',
                                body: '{{text}} wurde in die Zwischenablage kopiert'
                            }
                        }
                    }
                }
            }
        },

        fallbackLng: 'de',

        // have a common namespace used around the full app
        ns: ['translations'],
        defaultNS: 'translations',

        //keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false, // not needed for react!!
            //formatSeparator: ','
        },

        react: {
            wait: true
        }
    });

export default i18n;