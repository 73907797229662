import React from 'react'

export default class BaseModal extends React.Component {

    render() {
        const {handleClose, textClasses, headerTitle, headerSubtitle, body, footer} = this.props
        return (
            <div className='modal is-active'>
                <div className='modal-background' onClick={handleClose}/>
                <div className={'modal-card ' + (textClasses ? textClasses : '')}>
                    <header className='modal-card-head'>
                        <h2 className='title is-size-3'>{headerTitle}</h2>
                        <h4 className='subtitle is-size-5'>
                            {headerSubtitle}
                        </h4>
                        <button className='delete modal-pay-selection-close' aria-label='close' onClick={handleClose}/>
                    </header>
                    <section className='modal-card-body'>
                        {body}
                    </section>
                    <div className='modal-card-foot'>
                        {footer}
                    </div>
                </div>
            </div>
        )
    }
}