require('font-awesome/css/font-awesome.css')

// Require index.html so it gets copied to dist
// require('./index.html')

import 'babel-polyfill'

import 'moment/locale/de'
import 'moment/locale/en-gb'
import React from 'react'

import ReactDOM from 'react-dom'
import IdentificationForm from './js/components/IdentificationForm.jsx'
import TanForm from './js/components/TanForm.jsx'
import MonitoringForm from './js/components/MonitoringForm.jsx'
import MonitoringPasswordChange from './js/components/monitoring/MonitoringPasswordChange.jsx'
import CustomerAdministraiton from './js/components/monitoring/CustomerAdministration.jsx'
import UserAdministration from './js/components/monitoring/UserAdministration.jsx'
import CookieConfirmComponent from './js/components/CookieConfirmComponent.jsx'
import KeyGeneration from './js/components/monitoring/KeyGeneration.jsx'

import {I18nextProvider} from 'react-i18next'
import axios from 'axios'

import i18n from './i18n'
import * as modalHelper from './js/xignup-modal'
import * as languageHelper from './js/xignup-language'

import SockJS from 'sockjs-client'
import {Stomp} from '@stomp/stompjs'
import modernizr from './.modernizrrc.js';

// detect IE/Edge with smil, peerconnection for WebRTC supported browsers
// configuration in webpack loader and .modernizrrc.js
if (!modernizr.smil || !modernizr.peerconnection) {
    if(window.location.pathname !== '/browsernotsupported') {
        window.location.replace("/browsernotsupported");
    }
}

axios.defaults.xsrfCookieName = 'XSRF-TOKEN'
axios.defaults.xsrfHeaderName = 'X-XSRF-TOKEN'

document.addEventListener('DOMContentLoaded', () => {

    modalHelper.setupModals(
        ['modal-info-fernsignatur',
            'modal-pay-selection', 'modal-pay-data', 'modal-pay-action', 'modal-pay-success',
            'modal-id-data', 'modal-id-call', 'modal-id-tan', 'modal-id-success',
            'modal-cert-auth', 'modal-cert-password', 'modal-cert-success'])

    languageHelper.setupLanguageButtons(
        ['language-select-en', 'language-select-de'])

})

let iframeStatusRoot = document.getElementById('iframe-status-callback')
if (iframeStatusRoot) {
    let status = iframeStatusRoot.dataset.processState
    let targetOrigin = iframeStatusRoot.dataset.targetOrigin
    setTimeout(myCall, 1000)

    function myCall() {
        let statusObject = {}
        statusObject['status'] = status
        window.parent.postMessage(JSON.stringify(statusObject), targetOrigin)
    }
}

let continueCertificateActivationInFrameRoot = document.getElementById("continue-certificate-activation-in-frame")
if (continueCertificateActivationInFrameRoot) {
    let status = continueCertificateActivationInFrameRoot.dataset.processState
    if (status === 'CERT_ACTIVATION') {
        let ws = new SockJS('/endpoint', null, {transports: 'websocket'})
        let stompClient = Stomp.over(ws)
        let headers = {}
        stompClient.reconnect_delay = 5000
        stompClient.connect(headers, (frame) => {
            stompClient.subscribe('/user/queue/status', (message) => {
                window.location.replace(message.body)
            })
        })
    }
}

let identificationFormRoot = document.getElementById('identification-form-root')
if (identificationFormRoot) {
    ReactDOM.render(
        <I18nextProvider i18n={i18n}>
            <IdentificationForm
                data={identificationFormRoot.dataset}
            />
        </I18nextProvider>
        , identificationFormRoot)
}

let tanFormRoot = document.getElementById('veritan')
if (tanFormRoot) {
    ReactDOM.render(
        <I18nextProvider i18n={i18n}>
            <TanForm
                apiSubmitUrl={tanFormRoot.dataset.apiSubmitUrl || ''}
            />
        </I18nextProvider>
        , tanFormRoot)
}

let monitoringFormRoot = document.getElementById('monitoring-form-root')

if (monitoringFormRoot) {
    let customers = monitoringFormRoot.dataset.customers
    ReactDOM.render(
        <I18nextProvider i18n={i18n}>
            <MonitoringForm
                customerList={customers ? customers.split(',') : []}
                apiFilterUrl={monitoringFormRoot.dataset.apiFilterUrl || ''}
                apiDownloadCsvUrl={monitoringFormRoot.dataset.apiDownloadCsvUrl || ''}
            />
        </I18nextProvider>
        , monitoringFormRoot)
}

let passwordChangeFormRoot = document.getElementById('password-change-form-root')
if (passwordChangeFormRoot) {
    ReactDOM.render(
        <I18nextProvider i18n={i18n}>
            <MonitoringPasswordChange
                passwordChangeSubmitUrl={passwordChangeFormRoot.dataset.passwordChangeSubmitUrl || ''}
            />
        </I18nextProvider>
        , passwordChangeFormRoot)
}

let customerAdministrationRoot = document.getElementById('customer-administration-form-root')
if (customerAdministrationRoot) {
    ReactDOM.render(
        <I18nextProvider i18n={i18n}>
            <CustomerAdministraiton
                customerAdministrationSubmitUrl={customerAdministrationRoot.dataset.customerAdministrationSubmitUrl || ''}
                administrationOverviewUrl={customerAdministrationRoot.dataset.administrationOverviewUrl || ''}
                customer={customerAdministrationRoot.dataset.customer || {}}
                mode={customerAdministrationRoot.dataset.mode || ''}
            />
        </I18nextProvider>
        , customerAdministrationRoot)
}

let userAdministrationRoot = document.getElementById('user-administration-form-root')
if (userAdministrationRoot) {
    ReactDOM.render(
        <I18nextProvider i18n={i18n}>
            <UserAdministration
                getUsersUrl={userAdministrationRoot.dataset.getUsersUrl || ''}
                newUserUrl={userAdministrationRoot.dataset.newUserUrl || ''}
                updateUserUrl={userAdministrationRoot.dataset.updateUserUrl || ''}
                deleteUserUrl={userAdministrationRoot.dataset.deleteUserUrl || ''}
                resetUserUrl={userAdministrationRoot.dataset.resetUserUrl || ''}
                sendDocumentationUserUrl={userAdministrationRoot.dataset.sendDocumentationUserUrl || ''}
            />
        </I18nextProvider>
        , userAdministrationRoot)
}

let keyGenerationMonitoringFormRoot = document.getElementById('key-generation-monitoring-form-root')
if (keyGenerationMonitoringFormRoot) {
    ReactDOM.render(
        <I18nextProvider i18n={i18n}>
            <KeyGeneration
                customerApiKey={keyGenerationMonitoringFormRoot.dataset.customerApiKey || ''}
                customerWebIdEnabled={keyGenerationMonitoringFormRoot.dataset.customerWebIdEnabled || {}}
                customerNectEnabled={keyGenerationMonitoringFormRoot.dataset.customerNectEnabled || {}}
                customerAuthDataEnabled={keyGenerationMonitoringFormRoot.dataset.customerAuthDataEnabled || {}}
            />
        </I18nextProvider>
        , keyGenerationMonitoringFormRoot)
}


let cookieAcceptBanner = document.getElementById('cookie-accept-banner')
if (cookieAcceptBanner) {
    ReactDOM.render(
        <I18nextProvider i18n={i18n}>
                <CookieConfirmComponent/>
        </I18nextProvider>,
        cookieAcceptBanner
    )
}