import React from 'react'

export class FormButtonBar extends React.Component {
    render() {
        return (
            <div className='field is-horizontal'>
                <div className='field-body'>
                    <div className='field is-expanded is-grouped field-centered-buttons'>
                        {this.props.children}
                    </div>
                </div>
            </div>
        )
    }
}

export class FormRow extends React.Component {
    render() {
        const {label, t, children, additionalFieldClasses} = this.props
        return (
            <div className='field is-horizontal has-text-centered'>
                <div className='field-label is-normal'>
                    <label className='label'>{t(label)}</label>
                </div>
                <div className='field-body'>
                    <div className={'field ' + additionalFieldClasses || ''}>
                        {children}
                    </div>
                </div>
            </div>
        )
    }
}

export class FormInput extends React.Component {

    render() {
        const {name, error, warning, placeholder, autoComplete, handleInputChange, value, maxLength, disabled, type} = this.props
        return (
            <div className='control is-expanded has-icons-right'>
                <input name={name}
                       className={'input ' + (error ? 'is-danger' : '')}
                       type={type || 'text'}
                       placeholder={placeholder}
                       autoComplete={autoComplete}
                       onChange={handleInputChange}
                       value={value}
                       maxLength={maxLength}
                       disabled={disabled}
                />
                {error &&
                    <span className='icon is-small is-right'>
                        <i className='fa fa-exclamation-triangle'/>
                    </span>
                }
                {error &&
                    <p className='help is-danger'>{this.props.error}</p>
                }
                {warning &&
                    <p className='help is-info'>{this.props.warning}</p>
                }
            </div>
        )
    }
}

export class Button extends React.Component {
    render () {
        const {size, icon, text, onClick, disabled, className, style, name} = this.props
        return (
            <button className={'button ' + (className || '')  + (size || ' is-normal')}
                    onClick={onClick} disabled={disabled} style={style} name={name} type='button'>
                { icon &&
                    <span className='icon is-small'>
                        <i className={`fa ${icon}`}/>
                    </span>
                }
                { text &&
                    <span>{text}</span>
                }
            </button>
        )

    }
}

// Functional component
// export const Button = ({size = 'is-normal'}, className = '', icon, text, onClick, disabled, style, name) => {
//     return (
//         <button className={`button ${className} ${size}`}
//                 onClick={onClick} disabled={disabled} style={style} name={name} type="button">
//             { icon &&
//                 <span className='icon is-small'>
//                     <i className={`fa ${icon}`}/>
//                 </span>
//             }
//             { text &&
//             <span>{text}</span>
//             }
//         </button>
//     )
// }