import React from 'react'
import {FormRow, FormInput, FormButtonBar, Button}  from './../common/Forms.jsx'
import {withTranslation} from 'react-i18next'
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios/index'
import CenteredModal from './../modal/CenteredModal.jsx'
import {CopyToClipboard} from 'react-copy-to-clipboard';

class CustomerAdministration extends React.Component {
    constructor(props) {
        super(props)
        let paramCustomer =  JSON.parse(props.customer || '{}')
        this.state = {
            customer: paramCustomer,
            error: {},
            loadingSubmit: '',
            customerSubmitUrl: props.customerAdministrationSubmitUrl,
            modal: {
                displaySuccess: false,
                displayInternalError: false,
                displayCopyClipboard: false
            }
        }
    }

    handleInputChange = (event, validations) => {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
        const name = event.target.name

        let validationError = null
        if(validations.includes('isUuid') &&
            !/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/.test(value)) {
            validationError = this.props.t('admin.customer.error.noUuid')
        }
        if(validations.includes('onlyNumbers') && !/^\d{0,15}$/.test(value)){
             return
        }

        if(validations.includes('isNotEmpty') && value.trim() === '') {
            validationError = this.props.t('admin.customer.error.empty')
        }

        this.setStateCustomer(name, value, validationError)
    }

    setStateCustomer = (name, value, error) => {
        this.setState({
            customer: {
                ...this.state.customer, [name]: value
            },
            error: {
                ...this.state.error, [name]: error
            }
        })
    }

    handleCustomerSubmit = () => {
        this.setState({
            loadingSubmit: true
        })

        axios.post(this.state.customerSubmitUrl, JSON.stringify(this.state.customer), {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true
        }).then(success => this.handleCustomerSubmitSuccess(success.data)
        ).catch(error => this.handleCustomerSubmitFailure(error))
    }

    handleCustomerSubmitSuccess = (data) => {
        if (this.props.mode === 'new') {
            window.location.href = data
        } else {
            this.setState({
                customer: data,
                loadingSubmit: false,
                modal: {
                    displaySuccess: true
                }
            })
        }
    }

    handleCustomerSubmitFailure = (error) => {
        if(error.response.status === 409) {
            this.setState({
                error: {
                    ...this.state.error,  ['customerName']: this.props.t('admin.customer.error.conflict')
                },
                loadingSubmit: false
            })
        } else {
            this.setState({
                customer: JSON.parse(this.props.customer || '{}'),
                loadingSubmit: false,
                modal: {
                    displayInternalError: true
                }
            })
        }
    }

    handleGenerateUuid = (event) => {
        const name = event.currentTarget.name
        this.setStateCustomer(name, uuidv4(), null)
    }

    handleCopyStaticLink = (event) => {

    }

    closeModal = (target) => {
        this.setState({
            modal: {
                ...this.state.modal, [target]: false
            },
        })
    }


    render() {
        const {t, i18n} = this.props

        const noErrors = Object.values(this.state.error).every(x => (x === null || x === ''))

        const customerKeyUrl = `${window.location.origin}/auth?customerApiKey=${this.state.customer.customerApiKey}`

        const customerName = (
            <FormInput
                name='customerName'
                value={this.state.customer.customerName}
                error={this.state.error.customerName}
                handleInputChange={(event) => this.handleInputChange(event, ['isNotEmpty'])}/>
        )

        const registrationLimit = (
            <FormInput
                name='registrationLimit'
                value={this.state.customer.registrationLimit}
                error={this.state.error.registrationLimit}
                handleInputChange={(event) => this.handleInputChange(event, ['isNotEmpty', 'onlyNumbers'])}
            />
        )

        const customerApiKey = (
            <FormInput
                name='customerApiKey'
                value={this.state.customer.customerApiKey}
                error={this.state.error.customerApiKey}
                handleInputChange={(event) => this.handleInputChange(event, ['isNotEmpty', 'isUuid'])}
                disabled={!this.state.customer.activeApiCustomerKey}
                warning={this.state.customer.activeApiCustomerKey ? t('admin.customer.customerApiKey.warning') : null}
            />
        )

        const activeCustomerKeyCheckbox = (
            <div className='b-checkbox is-primary'>
                <input id='checkbox-customer-api-active' className='styled' type='checkbox' name='activeApiCustomerKey'
                       defaultChecked={this.state.customer.activeApiCustomerKey}
                       onClick={(event) => this.handleInputChange(event, [])}>
                </input>
                <label className='is-rel-centered' htmlFor='checkbox-customer-api-active' style={{paddingLeft: '3rem'}}>
                    <span className="icon is-small">
                        <i className="fa fa-key"/>
                    </span>
                </label>
            </div>
        )

        const idProviderSelection = (
            <React.Fragment>
                <div className='control horizontal-filter-component is-expanded is-horizontal '>
                    <div className='b-checkbox is-primary'>
                        <input id='checkbox-customer-webId-active' className='styled' type='checkbox' name='webIdEnabled'
                               defaultChecked={this.state.customer.webIdEnabled}
                               onClick={(event) => this.handleInputChange(event, [])}>
                        </input>
                        <label htmlFor='checkbox-customer-webId-active'>
                            <span>{t('monitoring.idProvider.webId')}</span>
                        </label>
                    </div>
                </div>
                <div className='control is-expanded '>
                    <div className='b-checkbox is-primary'>
                        <input id='checkbox-customer-authData-active' disabled className='styled' type='checkbox' name='authDataEnabled'
                               defaultChecked={this.state.customer.authDataEnabled}
                               onClick={(event) => this.handleInputChange(event, [])}>
                        </input>
                        <label htmlFor='checkbox-customer-authData-active' >
                            <span>{t('monitoring.idProvider.authData')}</span>
                        </label>
                    </div>
                </div>
                <div className='control is-expanded '>
                    <div className='b-checkbox is-primary'>
                        <input id='checkbox-customer-nect-active' disabled className='styled' type='checkbox' name='nectEnabled'
                               defaultChecked={this.state.customer.nectEnabled}
                               onClick={(event) => this.handleInputChange(event, [])}>
                        </input>
                        <label htmlFor='checkbox-customer-nect-active'>
                            <span>{t('monitoring.idProvider.nect')}</span>
                        </label>
                    </div>
                </div>
            </React.Fragment>
        )

        const customerKeyInputs = (
            <React.Fragment>
                <div className='control is-expanded  '>
                    {activeCustomerKeyCheckbox}
                </div>
                <div className='control is-expanded  '>
                    {customerApiKey}
                </div>
                <div className='control'>
                    <CopyToClipboard disabled={!this.state.customer.activeApiCustomerKey}
                                     text={customerKeyUrl}
                                     onCopy={() => this.setState({
                                         modal: {
                                             displayCopyClipboard: true,
                                             displayCopyClipboardText: t('admin.modal.copy.body', {text: customerKeyUrl})
                                         }})}>
                        <Button onClick={this.handleCopyStaticLink} name='staticLink' disabled={!this.state.customer.activeApiCustomerKey} icon='fa-clipboard'/>
                    </CopyToClipboard>
                </div>
                <div className='control'>
                    <Button onClick={this.handleGenerateUuid} name='customerApiKey' disabled={!this.state.customer.activeApiCustomerKey} text={ t('admin.customer.generateUuid')}/>
                </div>
            </React.Fragment>
        )


        const apiTicketKey = (
            <FormInput
                name='customerTicketApiKey'
                value={this.state.customer.customerTicketApiKey}
                error={this.state.error.customerTicketApiKey}
                handleInputChange={(event) => this.handleInputChange(event, ['isNotEmpty', 'isUuid'])}
                disabled={!this.state.customer.activeApiTicket}
            />
        )

        const activeTicketApiKeyCheckbox = (
            <div className='b-checkbox is-primary'>
                <input id='checkbox-ticket-api-active' className='styled' type='checkbox' name='activeApiTicket'
                       defaultChecked={this.state.customer.activeApiTicket}
                       onClick={(event) => this.handleInputChange(event, [])}>
                </input>
                <label className='is-rel-centered' htmlFor='checkbox-ticket-api-active' style={{paddingLeft: '3rem'}}>
                    <span className="icon is-small">
                        <i className="fa fa-ticket"/>
                    </span>
                </label>
            </div>
        )

        const ticketApiInputs = (
            <React.Fragment>
                <div className='control is-expanded  '>
                    {activeTicketApiKeyCheckbox}
                </div>
                <div className='control is-expanded  '>
                    {apiTicketKey}
                </div>
                <div className='control'>
                    <CopyToClipboard disabled={!this.state.customer.activeApiTicket}
                                     text={this.state.customer.customerTicketApiKey}
                                     onCopy={() => this.setState({
                                         modal: {
                                             displayCopyClipboard: true,
                                             displayCopyClipboardText: t('admin.modal.copy.body', {text: this.state.customer.customerTicketApiKey})
                                         }})}>
                        <Button onClick={this.handleCopyStaticLink} name='staticLink' icon='fa-clipboard'/>
                    </CopyToClipboard>
                </div>
                <div className='control'>
                    <Button onClick={this.handleGenerateUuid} name='customerTicketApiKey' disabled={!this.state.customer.activeApiTicket} text={ t('admin.customer.generateUuid')}/>
                </div>
            </React.Fragment>
        )

        const themeInput = (
            <input type='text' className='input'
                   value={this.state.customer.theme ?
                       JSON.stringify(this.state.customer.theme) : t('admin.customer.theme.defaultText')}
                   disabled/>
        )

        const poweredByInput = (
            <input type='text' className='input'
                   value={this.state.customer.poweredByMsgBundle ?
                       this.state.customer.poweredByMsgBundle : t('admin.customer.poweredByMsgBundle.defaultText')}
                   disabled/>
        )

        const submitButton = (
            <div className='control'>
                <Button className={'is-primary ' + (this.state.loadingSubmit ? 'is-loading' : '')}
                        onClick={this.handleCustomerSubmit}
                        disabled={this.state.loadingSubmit || !noErrors}
                        icon='fa-check'
                        text={t('admin.customer.button.submitChanges')}/>
            </div>
        )

        const backButton = (
            <div className='control'>
                <a className='button is-normal' href={this.props.administrationOverviewUrl}>
                     <span className='icon is-small'>
                        <i className='fa fa-close'/>
                     </span>
                    <span>{t('admin.customer.button.back')}</span>
                </a>
            </div>
        )

        const modalHolder = (
            <React.Fragment>
                {this.state.modal.displaySuccess &&
                <CenteredModal
                    title={t('admin.modal.success.title')}
                    body={t('admin.modal.success.body')}
                    handleClose={(() => this.closeModal('displaySuccess'))}/>
                }

                {this.state.modal.displayInternalError &&
                <CenteredModal
                    title={t('admin.modal.error.title')}
                    body={t('admin.modal.error.body')}
                    handleClose={(() => this.closeModal('displayInternalError'))}/>
                }
                {this.state.modal.displayCopyClipboard &&
                <CenteredModal
                    title={t('admin.modal.copy.title')}
                    body={this.state.modal.displayCopyClipboardText}
                    handleClose={(() => this.closeModal('displayCopyClipboard'))}/>
                }
            </React.Fragment>
        )

        return (
            <React.Fragment>
                {modalHolder}
                <h2 className='title has-text-centered is-size-4'>{this.state.customer.customerName}</h2>
                <form onSubmit={e => e.preventDefault()}>
                    <FormRow t={t} label={'admin.customer.customerName.label'}>{customerName}</FormRow>
                    <FormRow t={t} label={'admin.customer.registrationLimit.label'}>{registrationLimit} </FormRow>
                    <FormRow t={t} label={'admin.customer.identityProvider.label'}>{idProviderSelection} </FormRow>
                    <FormRow t={t} label={'admin.customer.customerTicketApiKey.label'} additionalFieldClasses='has-addons'>
                        {ticketApiInputs}
                    </FormRow>
                    <FormRow t={t} label={'admin.customer.customerApiKey.label'} additionalFieldClasses='has-addons'>
                        {customerKeyInputs}
                    </FormRow>
                    <FormRow t={t} label={'admin.customer.theme.label'}>{themeInput}</FormRow>
                    <FormRow t={t} label={'admin.customer.poweredByMsgBundle.label'}>{poweredByInput}</FormRow>
                    <FormButtonBar>{backButton}{submitButton}</FormButtonBar>
                </form>
            </React.Fragment>
        )
    }
}


export default withTranslation('translations')(CustomerAdministration)
