import React from 'react'

import {withTranslation} from 'react-i18next'

class MonitoringTable extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const t = this.props.t

        return <div>
            <table className='table is-fullwidth'>
                <thead>
                <tr>
                    {this.props.filterByTimeType === 'SESSION_START' && (
                        <TableHeader label={'monitoring.label.dateSessionStart.short'} tooltip={'monitoring.label.dateSessionStart.long'} t={t}/>
                    )}
                    {this.props.filterByTimeType === 'IDENTIFICATION' && (
                        <TableHeader label={'monitoring.label.dateIdentification.short'} tooltip={'monitoring.label.dateIdentification.long'} t={t}/>
                    )}
                    {this.props.filterByTimeType === 'CERTIFICATE_ACTIVATION' && (
                        <TableHeader label={'monitoring.label.dateCertActivation.short'} tooltip={'monitoring.label.dateCertActivation.long'} t={t}/>
                    )}
                    <TableHeader label={'monitoring.label.name'} t={t}/>
                    <TableHeader label={'monitoring.label.email'} t={t}/>
                    <TableHeader label={'monitoring.label.identificationState.short'} tooltip={'monitoring.label.identificationState.long'} t={t}/>
                    <TableHeader label={'monitoring.label.certificateState.short'} tooltip={'monitoring.label.certificateState.long'} t={t}/>
                    <TableHeader label={'monitoring.label.actionId.short'} tooltip={'monitoring.label.actionId.long'} t={t}/>
                    <TableHeader label={'monitoring.label.provider.short'} tooltip={'monitoring.label.provider.long'} t={t}/>
                    <TableHeader label={'monitoring.label.secondaryId.short'} tooltip={'monitoring.label.secondaryId.long'} t={t}/>
                    {this.props.customerList.length > 0 && (
                        <TableHeader label={'monitoring.label.customer'} t={t}/>
                    )}
                </tr>
                </thead>
                <tbody>
                {this.props.displayData && this.props.displayData.map((row, index) =>
                    <tr key={index}>
                        <td className='date-min-width tooltip is-tooltip-primary' data-tooltip={row.dateHistory}>
                            {row.date.format('DD.MM.YY, HH:mm')}
                        </td>
                        <td className='tooltip is-tooltip-primary' data-tooltip={row.cell}>{row.name}</td>
                        <td><a href={'mailto:' + row.email}>{row.email}</a></td>
                        <td>{row.identificationState}</td>
                        <td>{row.certificateState}</td>
                        <td>{row.actionId}</td>
                        <td>{row.idProvider}</td>
                        <td>{row.secondaryId}</td>
                        {this.props.customerList.length > 0 && (
                            <td>{row.customerName}</td>
                        )}
                    </tr>
                )}
                </tbody>
            </table>
            {this.props.displayData.length === 0 && (
                <h1 className='monitoring-table-empty'>{t('monitoring.noResultFound')}</h1>
            )}
        </div>
    }
}

class TableHeader extends React.Component {

    render() {
        const t = this.props.t

        return (
            <th className={this.props.tooltip ? 'tooltip is-tooltip-primary' : ''} data-tooltip={t(this.props.tooltip)}>
                {t(this.props.label)}
            </th>
        )
    }
}

export default withTranslation('translations')(MonitoringTable)
