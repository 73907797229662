import React from 'react'
import {Button}  from './../common/Forms.jsx'
import {withTranslation} from 'react-i18next'
import CenteredModal from './../modal/CenteredModal.jsx'
import axios from "axios";
import {CopyToClipboard} from "react-copy-to-clipboard";

class KeyGeneration extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            link: null,
            error: {},
            modal: {
                displayInternalError: false,
                displayCopyClipboard: false
            },
            apiKey: this.props.customerApiKey,
            webIdEnabled: this.props.customerWebIdEnabled === 'true',
            nectEnabled: this.props.customerNectEnabled === 'true',
            authDataEnabled: this.props.customerAuthDataEnabled === 'true',
        }
    }


    handleClickNewLink =  () => {
        const json = JSON.stringify({ ticketApiKey: this.state.apiKey });
        let axiosConfig = {
            headers: {
                "Content-Type": 'application/json',
                "Accept": "application/json"
            }
        };
        axios.post(window.location.toString().replace('/monitoring/info', '/webservices/api/v1/generateTicket'), json, axiosConfig)
            .then((response) => {
                if(response.data.errorObject){
                    this.handleLinkGenerationFailure()
                } else {
                    this.addNewLink(response.data.successObject.url);
                }
            }, (error) => {
                this.handleLinkGenerationFailure()
            });
    }

    handleLinkGenerationFailure = () => {
        this.setState({
            modal: {
                displayInternalError: true
            }
        })
    }

    addNewLink = (newLink) => {
        this.setState({
            link: newLink
        })
    }

    closeModal = (target) => {
        this.setState({
            modal: {
                ...this.state.modal, [target]: false
            },
        })
    }

    handleClickCopyLink = (event) => {

    }

    render() {
        const {t, i18n} = this.props

        const noErrors = Object.values(this.state.error).every(x => (x === null || x === ''))

        const header = (

            <div className='columns has-text-centered' style={{alignItems: 'center', marginTop: 'auto'}}>
                <div className='column label is-10'>
                    {t('monitoring.linkgen.title')}
                </div>
                <Button onClick={this.handleClickNewLink} icon='fa-plus' text={t('monitoring.linkgen.new')}
                        style={{marginLeft: 'auto'}} className='column'/>
            </div>
        )

        const listLink = this.state.link ?
            (
                <React.Fragment>
                    <div className='columns has-text-centered has-text-grey-dark' style={{alignItems: 'center'}}>
                        <div className='column is-11'>{this.state.link}</div>
                        <div className='column'>
                            <CopyToClipboard text={this.state.link}
                                             onCopy={() => this.setState({
                                                 modal: {
                                                     displayCopyClipboard: true,
                                                     displayCopyClipboardText: t('admin.modal.copy.body', {text: this.state.link})
                                                 }})}>
                                <Button onClick={this.handleClickCopyLink} name='generatedLink' icon='fa-clipboard'/>
                            </CopyToClipboard>
                        </div>
                    </div>
                </React.Fragment>
            ) : (
                <h1 className='monitoring-table-empty is-margin-bottom'>{t('monitoring.linkgen.noLink')}</h1>
            )

        const modalHolder = (
            <React.Fragment>
                {this.state.modal.displayCopyClipboard &&
                <CenteredModal
                    title={t('admin.modal.copy.title')}
                    body={this.state.modal.displayCopyClipboardText}
                    handleClose={(() => this.closeModal('displayCopyClipboard'))}/>
                }

                {this.state.modal.displayInternalError &&
                <CenteredModal
                    title={t('monitoring.modal.error.title')}
                    body={t('monitoring.modal.error.body')}
                    handleClose={(() => this.closeModal('displayInternalError'))}/>
                }
            </React.Fragment>
        )


        return (
            <React.Fragment>
                {this.state.apiKey &&
                    modalHolder
                }
                {this.state.apiKey && this.state.webIdEnabled &&
                    <div>
                        <div className='is-divider' data-content={t('monitoring.idProvider.webId')}/>
                        {header}
                        <div className='is-margin-bottom-large'>
                        {listLink}
                        </div>
                    </div>
                }
                {this.state.apiKey && this.state.authDataEnabled &&
                    <div>
                        <div className='is-divider' data-content={t('monitoring.idProvider.authData')}/>
                        {header}
                        <div className='is-margin-bottom-large'>
                            {listLink}
                        </div>
                    </div>
                }
                {this.state.apiKey && this.state.nectEnabled &&
                    <div>
                        <div className='is-divider' data-content={t('monitoring.idProvider.nect')}/>
                        {header}
                        <div className='is-margin-bottom-large'>
                            {listLink}
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    }
}


export default withTranslation('translations')(KeyGeneration)
