import React from 'react'
import ReactDOM from 'react-dom'
import BaseModal from './BaseModal.jsx'
import {withTranslation} from 'react-i18next'

const modalRoot = document.getElementById('dynamic-modal-root')

class InternalErrorModal extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        const {t, i18n} = this.props

        let internalErrorModal = (
            <BaseModal
                headerTitle={t('modal.internal.headerTitle')}
                headerSubtitle={t('modal.internal.headerSubtitle')}
                body={t('modal.internal.body')}
                handleClose={this.props.handleClose}/>
        )

        return (
            ReactDOM.createPortal(
                internalErrorModal,
                modalRoot,
            )
        )
    }
}

export default withTranslation('translations')(InternalErrorModal)