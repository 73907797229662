export let setupModals = (modalNameList) => {
  for(let modalName of modalNameList) {
      setupModal(modalName)
  }
}

export let setupModal = (modalName) => {

    const open = '-open'
    const close = '-close'

    let openElementClass = modalName.concat(open)
    let closeElementClass = modalName.concat(close)

    for (let element of document.getElementsByClassName(openElementClass)) {
        element.addEventListener('click', () => {
            document.getElementById(modalName).classList.add('is-active')
        })
    }

    for (let element of document.getElementsByClassName(closeElementClass)) {
        element.addEventListener('click', () => {
            document.getElementById(modalName).classList.remove('is-active')
        })
    }
}
